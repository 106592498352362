













































































import {
  Component, Vue, Watch, Ref
} from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Models } from '@mtap-smartcity/api';
import {
  DevicesState, DevicesAction, DevicesActionType, DevicesGetter, DevicesGetterType
} from '@/store/modules/devices/types';
import {
  ScenariosAction,
  ScenariosActionType,
  ScenariosState
} from '@/store/modules/scenarios/types';
import {
  CircuitsAction, CircuitsActionType, CircuitsGetter, CircuitsGetterType
} from '@/store/modules/circuits/types';
import {
  AppState, AppAction, AppActionType, AppGetter, AppGetterType
} from '@/store/modules/app/types';
import { GroupsAction, GroupsActionType } from '@/store/modules/groups/types';
import { GraphsAction, GraphsActionType } from '@/store/modules/graphs/types';
import {
  isLamp, isSensor, isGateway, hasCoordinates, isControlCabinetTelemetry
} from '@/utils/type_check';
import MapMarker from './TheMapMarker/TheMapMarker.vue';
import MapCluster from './TheMapClaster/TheMapCluster.vue';
import DeviceActionPopup from './DeviceActionPopup/DeviceActionPopup.vue';
import {
  TelemetryAction, TelemetryActionType, TelemetryGetter, TelemetryGetterType
} from '@/store/modules/telemetry/types';
import { AdminState, UserGetter, UserGetterType } from '@/store/modules/admin/types';
import { isInAllowedDelay } from '@/utils/allowed_delay';

const circuits = namespace('circuits');
const app = namespace('app');
const admin = namespace('admin');
const devices = namespace('devices');
const scenarios = namespace('scenarios');
const groups = namespace('groups');
const graphs = namespace('graphs');
const telemetry = namespace('telemetry');

const publicPath = process.env.BASE_URL;

@Component({
  components: {
    MapMarker,
    MapCluster,
    DeviceActionPopup,
    // MapInfoWindowContent
  }
})
/**
 * @vuese
 * @group The map
 * Main map component
 */
export default class TheMap extends Vue {
  @Ref() circuitPolygon!: any;

  @Ref() mapRef!: any;

  @app.State
  mapSelectionEnabled!: AppState['mapSelectionEnabled'];

  @app.State
  mapFocusPoint!: AppState['mapFocusPoint'];

  @app.State
  showCircuitActionsCard!: AppState['showCircuitActionsCard'];

  @app.State
  triggerHistoricals!: AppState['triggerHistoricals'];

  @app.State
  selectedAnalyticsTab!: AppState['selectedAnalyticsTab'];

  @app.State
  selectedTab!: AppState['selectedTab'];

  @admin.State
  permissions!: AdminState['permissions'];

  @devices.State
  devices!: DevicesState['devices'];

  @scenarios.State
  scenarioElements!: ScenariosState['scenarioElements'];

  @devices.State
  selectedMarkerId!: DevicesState['selectedMarkerId'];

  @admin.Getter(UserGetter.GetPermissions)
  GetPermissions!: UserGetterType['GET_PERMISSIONS'];

  @app.Getter(AppGetter.GetRuntimeConfig)
  runtimeConfig!: AppGetterType['GET_RUNTIME_CONFIG'];

  @devices.Getter(DevicesGetter.GetDevice)
  getDevice!: DevicesGetterType['GET_DEVICE'];

  @telemetry.Getter(TelemetryGetter.GetLastTelemetry)
  getLastTelemetry!: TelemetryGetterType['GET_LAST_TELEMETRY'];

  @circuits.Getter(CircuitsGetter.GetCircuit)
  getCircuit!: CircuitsGetterType['GET_CIRCUIT'];

  @app.Action(AppAction.SetSelectedTab)
  setSelectedTab!: AppActionType['SET_SELECTED_TAB'];

  @circuits.Action(CircuitsAction.FetchCircuits)
  fetchCircuits!: CircuitsActionType['FETCH_CIRCUITS'];

  @devices.Action(DevicesAction.FetchDevices)
  fetchDevices!: DevicesActionType['FETCH_DEVICES'];

  @graphs.Action(GraphsAction.FetchGraphs)
  fetchGraphs!: GraphsActionType['FETCH_GRAPHS'];

  @telemetry.Action(TelemetryAction.FetchLastTelemetriesFrom)
  fetchLastTelemetriesFrom!: TelemetryActionType['FETCH_LAST_TELEMETRIES_FROM'];

  @telemetry.Action(TelemetryAction.SetSelectedReading)
  setSelectedReading!: TelemetryActionType['SET_SELECTED_READING'];

  @devices.Action(DevicesAction.SetSelectedMarkerId)
  setSelectedMarkerId!: DevicesActionType['SET_SELECTED_MARKER_ID'];

  @devices.Action(DevicesAction.SetInsidePolygonMarkers)
  setInsidePolygonMarkers!: DevicesActionType['SET_INSIDE_POLYGON_MARKERS'];

  @scenarios.Action(ScenariosAction.FetchScenarios)
  fetchScenarios!: ScenariosActionType['FETCH_SCENARIOS'];

  @scenarios.Action(ScenariosAction.FetchScenarioElements)
  fetchScenarioElements!: ScenariosActionType['FETCH_SCENARIO_ELEMENTS'];

  @groups.Action(GroupsAction.FetchGroups)
  fetchGroups!: GroupsActionType['FETCH_GROUPS'];

  map: google.maps.Map | null = null;

  mapType: string = 'roadmap';

  clusterImagePath = `${publicPath}m1.png`;

  rightClickCoordinates: google.maps.LatLngLiteral = {
    lat: 0,
    lng: 0
  };

  clickedMarker: Models.Devices.Device | null = null;

  showDeviceActionPopup = false;

  devicePopupPosition = {
    left: '0px',
    top: '0px'
  };

  polygonCoordinates: Array<google.maps.LatLngLiteral> = [];

  myStyles = [
    {
      featureType: 'poi',
      elementType: 'labels',
      stylers: [{ visibility: 'off' }]
    }
  ];

  mapOptions = {
    zoomControl: false,
    mapTypeId: 'roadmap',
    mapTypeControlOptions: {
      mapTypeIds: ['roadmap', 'satellite'],
      style: 3,
      position: 3
    },
    scaleControl: false,
    streetViewControl: false,
    rotateControl: false,
    clickableIcons: true,
    fullscreenControl: false,
    disableDefaultUi: false,
    styles: this.myStyles
  };

  intervalID!: any;

  numberOfIntervals: number = 0;

  // showInfoWindow = false;

  // infoWindowPosition: google.maps.LatLngLiteral = { lat: 0, lng: 0 }

  // deviceTelemetry: Models.Telemetries.SensorMtap1V1Telemetry | Models.Telemetries.ControlCabinetMtap1V1Telemetry | null = null

  // BELOW DATA IS DEPRECATED OR TEMPORARILY OUT OF USE
  // infoWindowOptions = {
  //   pixelOffset: {
  //     width: 0,
  //     height: -40
  //   }
  // }

  userLocation: { latitude: number, longitude: number } = {
    latitude: 0,
    longitude: 0
  };

  get polygonOptions() {
    return {
      strokeColor: '#ed8611',
      strokeOpacity: 0.5,
      fillColor: '#ed8611',
      fillOpacity: 0.2,
      editable: !this.showCircuitActionsCard,
      draggable: !this.showCircuitActionsCard
    };
  }

  get filteredMarkers() {
    // we don't want to display bridges atm
    const dv = this.devices.filter((d) => {
      if (isLamp(d) && hasCoordinates(d.parameters.device)) return true;
      if (isSensor(d) && hasCoordinates(d.parameters.device)) return true;
      // if (isControlCabinet(d) && hasCoordinates(d.parameters.device)) return true;
      if (isGateway(d) && hasCoordinates(d.parameters.device)) return true;
      return false;
    });
    return dv;
  }

  get deviceHasTelemetry() {
    if (!this.clickedMarker) return false;
    return isLamp(this.clickedMarker)
      || isSensor(this.clickedMarker)
      || (
        isGateway(this.clickedMarker)
        && this.isControlCabinet(this.clickedMarker)
      );
  }

  get permissionCheckAnalytics(): boolean {
    if (!this.permissions.length) return false;
    const getPermissions = this.GetPermissions.find((item) => item.type === 'analytics');
    return getPermissions.read && this.deviceHasTelemetry;
  }

  get permissionCheckControl(): boolean {
    if (!this.permissions.length) return false;
    if (!this.clickedMarker || !isLamp(this.clickedMarker)) return false;
    const permissionDevicesWrite = this.GetPermissions.find((item) => item.type === 'devices')?.write;
    const permissionCircuitsWrite = this.GetPermissions.find((item) => item.type === 'circuits')?.write;
    const permissionGroupsWrite = this.GetPermissions.find((item) => item.type === 'groups')?.write;
    return permissionDevicesWrite || permissionCircuitsWrite || permissionGroupsWrite;
  }

  get permissionCheckDevices(): boolean {
    if (!this.permissions.length) return false;
    const getPermissions = this.GetPermissions.find((item) => item.type === 'devices');
    return getPermissions.read;
  }

  get permissionDeviceActionPopup(): boolean {
    const permitted = this.permissionCheckDevices || this.permissionCheckAnalytics || this.permissionCheckControl;
    return permitted;
  }

  isControlCabinet(device: Models.Devices.Model): boolean {
    if (!device) return false;
    const gatewayTelemetry = this.getLastTelemetry(device.object_id, device.device_type);
    if (!gatewayTelemetry) return false;
    return isControlCabinetTelemetry(gatewayTelemetry);
  }

  circuitColor(circuitId: string | null) {
    if (circuitId === null) return '';
    const { color } = this.getCircuit(circuitId) ?? { color: '' };
    return color;
  }

  deviceIsOnline(objectId: string, deviceType: Models.Constants.DeviceType) {
    const deviceTelemetry = this.getLastTelemetry(objectId, deviceType);
    return isInAllowedDelay(deviceTelemetry, this.runtimeConfig);
  }

  updatePolygonCoordinates() {
    const mvcArray = (this.circuitPolygon.$polygonObject as google.maps.Polygon).getPath()
      .getArray();
    const selections = mvcArray.map((item) => item.toJSON());
    this.polygonCoordinates = selections;
  }

  addPolygonCoordinate(event: google.maps.MapMouseEvent) {
    this.polygonCoordinates.push({
      lat: event.latLng.lat(),
      lng: event.latLng.lng()
    });
  }

  handleMapClick(event: google.maps.MapMouseEvent) {
    if (this.mapSelectionEnabled) {
      // if user is drawing circuit on the map add polygon node by clicking on the map
      this.addPolygonCoordinate(event);
    } else {
      // otherwise close the main card
      this.setSelectedTab(null);
      this.$store.dispatch('turnOffProfile');
    }
  }

  handleMapRightClick(event: google.maps.MapMouseEvent) {
    this.rightClickCoordinates = event.latLng.toJSON();
  }

  // native context menu event needs to be handled separately to distinguish between map right click and icon right click
  // since right-clicking on the marker fires also a right click event listener on the map;
  // I was not able to stop the event propagation, hence the workaround
  handleMapContextMenu(event: MouseEvent) {
    // disable if the user is currently drawing circuit on the map
    if (this.mapSelectionEnabled) return;
    this.clickedMarker = null;
    const {
      pageX,
      pageY
    } = event;
    this.devicePopupPosition.left = `${pageX}px`;
    this.devicePopupPosition.top = `${pageY}px`;
    this.showDeviceActionPopup = true;
  }

  handleMapTypeChange(mapType: string) {
    this.mapType = mapType;
  }

  handleIconClick(m: Models.Devices.Device, event: MouseEvent) {
    if (this.mapSelectionEnabled) return;
    this.clickedMarker = m;
    this.setSelectedMarkerId(m.id as number);
    if (!this.permissionDeviceActionPopup) return;
    const {
      pageX,
      pageY
    } = event;
    this.devicePopupPosition.left = `${pageX}px`;
    this.devicePopupPosition.top = `${pageY}px`;
    this.showDeviceActionPopup = true;

    const lastTelemetry = this.getLastTelemetry(m.object_id, m.device_type);
    this.setSelectedReading({ reading: lastTelemetry });
  }

  // BELOW FUNCTIONALITY IS DEPRECATED OR TEMPORARILY OUT OF USE
  // async handleIconClick(marker: Models.Devices.Device) {
  //   // disable if the user is currently drawing circuit on the map
  //   if (this.mapSelectionEnabled) return;
  //   if (isLamp(marker)) {
  //     // select the lamp and open the control card
  //     this.setSelectedMarkerId(marker.id as number);
  //   } else if (isSensor(marker) || isControlCabinet(marker)) {
  //     // display telemetry of the device in the native map info window
  //     const { latitude: lat, longitude: lng } = marker.parameters.device;
  //     this.infoWindowPosition = { lat, lng };
  //     try {
  //       await this.fetchDeviceTelemetry({ objectId: marker.object_id });
  //       const lastTelemetry = this.lastTelemetries;
  //       const markerTelemetry = lastTelemetry.find(
  //         (reading: any) => reading.object_id === marker.object_id
  //       && reading.device_type === marker.device_type
  //       ) as Models.Telemetries.SensorMtap1V1Telemetry | Models.Telemetries.ControlCabinetMtap1V1Telemetry;
  //       this.deviceTelemetry = markerTelemetry !== undefined ? markerTelemetry : null;
  //       this.showInfoWindow = true;
  //     } catch (error) {
  //       this.deviceTelemetry = null;
  //       this.showInfoWindow = true;
  //     }
  //   }
  // }

  @Watch('polygonCoordinates')
  onPolygonCoordinatesChange(newCoordinatesArray: Array<google.maps.LatLngLiteral>) {
    const polygon = new window.google.maps.Polygon({
      paths: newCoordinatesArray
    });
    if (newCoordinatesArray.length) {
      const selectedMarkers = this.devices
        .filter(isLamp)
        .filter((d) => {
          const latLng = new window.google.maps.LatLng(d.parameters.device.latitude, d.parameters.device.longitude);
          return window.google.maps.geometry.poly.containsLocation(
            latLng,
            polygon
          );
        });
      this.setInsidePolygonMarkers(selectedMarkers);
    }
  }

  @Watch('mapSelectionEnabled')
  onMapSelectionEnabledChange(enabled: boolean) {
    // clear polygon coordinates if map selection is switched off
    if (!enabled) {
      this.polygonCoordinates = [];
    }
  }

  @Watch('mapFocusPoint')
  onMapFocusPointChange(coordinates: google.maps.LatLngLiteral) {
    if (this.map) this.map.panTo(coordinates);
  }

  @Watch('selectedTab')
  onSelectedTabChange(newValue: string) {
    if (newValue && this.showDeviceActionPopup) this.showDeviceActionPopup = false;
  }

  @Watch('triggerHistoricals', { deep: true })
  fetchHistoricalData() {
    const { timeHistoricals } = this.$store.state.app;
    this.fetchCircuits({ unixTime: timeHistoricals });
    this.fetchDevices({ unixTime: timeHistoricals });
    this.fetchGraphs({ unixTime: timeHistoricals });
    this.fetchGroups({ unixTime: timeHistoricals });
    this.fetchScenarios({ unixTime: timeHistoricals });
    this.fetchScenarioElements({ unixTime: timeHistoricals });
  }

  created() {
    this.fetchCircuits();
    this.fetchDevices();
    this.fetchGraphs();
    this.fetchGroups();
    this.fetchScenarios();
    this.fetchScenarioElements();
    this.fetchLastTelemetriesFrom();
    this.intervalID = setInterval(() => {
      if (this.selectedAnalyticsTab !== 'telemetry') {
        this.fetchLastTelemetriesFrom({ attributes: 'object_id,timestamp' });
      }
    }, 30000);
  }

  mounted() {
    this.mapRef.$mapPromise.then((map: google.maps.Map) => {
      this.map = map;
    });

    navigator.geolocation.getCurrentPosition(() => {
      navigator.geolocation.watchPosition((position) => {
        const {
          latitude,
          longitude
        } = position.coords;
        this.userLocation = {
          latitude,
          longitude
        };
      });
    });
  }

  beforeDestroy() {
    clearInterval(this.intervalID);
  }
}
