<template functional>
  <div class="c-async-error">
    <v-icon class="c-async-error__icon">mdi-alert-circle</v-icon>
    <p class="c-async-error__message">
      An error occured while loading the component <br>
      Try again later.
    </p>
  </div>
</template>

<script>
import Vue from 'vue';

/**
 * @vuese
 * @group Base Components
 * Fallback component displayed when loading of an async component results in error.
 */
export default Vue.extend({
  name: 'AsyncError',
  functional: true
});
</script>

<style lang="scss">
@import '@/styles/main';

.c-async-error {
  background-color: color-shade($colors, 'secondary', 'base');
  height: 100%;
  width: 100%;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  &__icon {
    margin-bottom: 0.5rem;
  }
  &__message {
    text-align: center;
  }
}
</style>
