import { KeycloakRole } from '@/types/users';
import { Models } from '@mtap-smartcity/api';
import { CompetenceData, CompetenceType } from '@/constants/competencesFormObj';

interface CompetencesTableRow extends CompetenceData {
  id: string,
  type: CompetenceType
}

interface Group extends Models.Auth.Groups.GroupsModel {
  roles?: Models.Auth.Roles.RolesModel[],
}

interface AdminState {
  // user: Models.Bff.Auth.Users.Model,
  user: Models.Bff.Auth.Users.Model,
  // users: Models.Bff.Auth.Users.Model[],
  users: Models.Bff.Auth.Users.Model[],
  // selectedUser: Models.Bff.Auth.Users.Model | null,
  selectedUser: Models.Bff.Auth.Users.Model | null,
  // groups: Models.Auth.Groups.GroupsModel[],
  groups: Group[],
  selectedGroup: Group | null,
  availableRoles: Models.Auth.Roles.RolesModel[],
  permissions: [],
  createUserFlag: boolean
  deleteUserFlag: boolean
}

enum UserGetter {
  GetUser = 'GET_USER',
  GetPermissions = 'GET_PERMISSIONS',
  GetGroupRoles = 'GET_GROUP_ROLES',
  GetFilteredGroupRoles = 'GET_FILTERED_GROUP_ROLES',
}

interface IUser{
  user: { id: string }
}

type UserGetterType = {
  [UserGetter.GetUser]: Partial<Models.Bff.Auth.Users.Model>,
  [UserGetter.GetPermissions]: Array<any>,
  [UserGetter.GetGroupRoles]: (groupId: string) => Models.Auth.Roles.RolesModel[] | undefined,
  [UserGetter.GetFilteredGroupRoles]: (groupId: string) => Models.Auth.Groups.GroupsModel[],
}

enum AdminAction {
  FetchUsers = 'FETCH_USERS',
  FetchUser = 'FETCH_USER',
  AddUserPlaceholder = 'ADD_USER_PLACEHOLDER',
  RemoveUserPlaceholder = 'REMOVE_USER_PLACEHOLDER',
  CreateUser = 'CREATE_USER',
  UpdateUser = 'UPDATE_USER',
  DeleteUser = 'DELETE_USER',
  ChangeUserPassword = 'CHANGE_USER_PASSWORD',
  SetSelectedUser = 'SET_SELECTED_USER',
  AddUserToGroup = 'ADD_USER_TO_GROUP',
  FetchGroups = 'FETCH_GROUPS',
  AddGroupPlaceholder = 'ADD_GROUP_PLACEHOLDER',
  RemoveGroupPlaceholder = 'REMOVE_GROUP_PLACEHOLDER',
  CreateGroup = 'CREATE_GROUP',
  UpdateGroup = 'UPDATE_GROUP',
  DeleteGroup = 'DELETE_GROUP',
  SetSelectedGroup = 'SET_SELECTED_GROUP',
  SetUserPermissions = 'SET_USER_PERMISSIONS',
  Permissions = 'PERMISSIONS',
  GroupCompetences = 'GROUP_COMPETENCES',
  FetchAvailableRoles = 'FETCH_AVAILABLE_ROLES',
  FetchGroupRoles = 'FETCH_GROUP_ROLES',
  AddRoleToGroup = 'ADD_ROLE_TO_GROUP',
  ModifyRoleToUser = 'MODIFY_ROLE_TO_USER',
  SetCreateUserFlag = 'SET_CREATE_USER_FLAG',
  ModifyGroupRoles = 'MODIFY_GROUP_ROLES',
}

type AdminActionType = {
  [AdminAction.FetchUsers]: () => void
  [AdminAction.FetchUser]: (userId: string) => any;
  [AdminAction.SetUserPermissions]: (roles: Models.Auth.Groups.GroupsModel[]) => Promise<void>;
  [AdminAction.Permissions]: (array: KeycloakRole[]) => any;
  [AdminAction.GroupCompetences]: (roles: Models.Auth.Roles.RolesModel[]) => CompetencesTableRow[];
  [AdminAction.AddUserPlaceholder]: () => void
  [AdminAction.RemoveUserPlaceholder]: () => void
  // [AdminAction.CreateUser]: (user: Models.Bff.Auth.Users.Model) => Promise<void>
  [AdminAction.CreateUser]: (user: Models.Bff.Auth.Users.Model) => Promise<void>
  // [AdminAction.UpdateUser]: (user: Models.Bff.Auth.Users.Model) => Promise<void>
  [AdminAction.UpdateUser]: (user: Models.Bff.Auth.Users.Model) => Promise<void>
  [AdminAction.DeleteUser]: (userId: string) => Promise<void>
  [AdminAction.ChangeUserPassword]: (user: Partial<Models.Bff.Auth.Users.Model>) => Promise<void>
  // [AdminAction.SetSelectedUser]: (user: Models.Bff.Auth.Users.Model | null) => void
  [AdminAction.SetSelectedUser]: (user: Models.Bff.Auth.Users.Model | null) => void
  [AdminAction.AddUserToGroup]: (payload: Models.Auth.Groups.GroupsModel) => Promise<void>
  [AdminAction.FetchGroups]: () => void
  [AdminAction.AddGroupPlaceholder]: () => void
  [AdminAction.RemoveGroupPlaceholder]: () => void
  [AdminAction.CreateGroup]: (group: Models.Auth.Groups.GroupsModel) => Promise<void>
  [AdminAction.UpdateGroup]: (group: Models.Auth.Groups.GroupsModel) => Promise<void>
  [AdminAction.DeleteGroup]: (groupId: string) => Promise<void>
  [AdminAction.SetSelectedGroup]: (group: Models.Auth.Groups.GroupsModel | null) => void
  [AdminAction.FetchAvailableRoles]: () => void
  [AdminAction.FetchGroupRoles]: (groupId: string) => Promise<void>
  [AdminAction.AddRoleToGroup]: (payload: { groupId: string, role: KeycloakRole, add: boolean }) => void
  [AdminAction.ModifyRoleToUser]: (role: Models.Auth.Roles.RolesModel) => Promise<void>
  [AdminAction.SetCreateUserFlag]: () => void
  [AdminAction.ModifyGroupRoles]: (payload: {groupId: string, roles: Models.Auth.Roles.RolesModel[] }) => Promise<void>

}

export {
  UserGetterType,
  UserGetter,
  AdminState,
  AdminAction,
  AdminActionType,
  CompetencesTableRow,
  IUser
};
