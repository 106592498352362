import Fetch from '@/utils/fetch';
import { Models } from '@mtap-smartcity/api';

const fetchGraphs = (unixTime) => Fetch(`/api/graphs?pointInTime=${unixTime}`, {});

const createGraph = (graph: Models.ScenarioGraphs.Model) => Fetch<Models.ScenarioGraphs.Model>('/api/graphs', {
  method: 'POST',
  body: graph
});

const updateGraph = (graph: Models.ScenarioGraphs.Model) => {
  const update = { ...graph };
  delete update.id;
  return Fetch(`/api/graphs/${graph.uuid}`, {
    method: 'PUT',
    body: update
  });
};

const deleteGraph = (uuid: string) => Fetch(`/api/graphs/${uuid}`, {
  method: 'DELETE'
});

export {
  fetchGraphs,
  createGraph,
  updateGraph,
  deleteGraph
};
