














































import { nameFilter } from '@/utils/name_filter';
import {
  Component, Vue, Prop, Ref
} from 'vue-property-decorator';

export interface Option {
  text: string,
  value: number | string | null
}

const isOption = (e: any) => typeof e.text === 'string' && e.value !== undefined;
export const isOptionsArr = (arr: any) => Array.isArray(arr) && arr.every(isOption);

@Component
/**
 * @group Base Components
 * Custom select
 */
export default class BaseSelect extends Vue {
  @Ref('dropdown') readonly dropdown!: HTMLDivElement;

  // Available select options
  @Prop({
    type: Array,
    required: true,
    validator: isOptionsArr
  }) readonly options!: Array<Option>;

  // Selected option's value
  @Prop({ type: [Number, String] }) readonly value!: number | string | null;

  // Placeholder text
  @Prop({ type: String }) readonly placeholder!: string;

  // White background variant
  @Prop(Boolean) readonly light!: boolean;

  // No background + underline variant
  @Prop(Boolean) readonly underline!: boolean;

  // Smaller select variant
  @Prop({
    type: Boolean,
    default: false
  }) readonly small!: boolean;

  optionsMenuOpen = false;

  searchPhrase = '';

  get displayName() {
    if (this.value !== null && this.options) {
      const selected = this.options.find((o) => o.value === this.value);
      if (selected) {
        return selected.text;
      }
      return this.placeholder;
    }
    return this.placeholder;
  }

  get filteredOptions(): Option[] {
    return this.options.filter((option) => nameFilter(option.text, this.searchPhrase));
  }

  closeSelect() {
    this.optionsMenuOpen = false;
    this.searchPhrase = '';
  }

  toggleSelect() {
    this.optionsMenuOpen = !this.optionsMenuOpen;
    this.searchPhrase = '';
    const dropdownRect = this.dropdown.getBoundingClientRect();
    if (dropdownRect.top + dropdownRect.height > window.innerHeight) {
      this.dropdown.style.removeProperty('top');
      this.dropdown.style.setProperty('bottom', '100%');
    } else {
      this.dropdown.style.removeProperty('bottom');
      this.dropdown.style.setProperty('top', '100%');
    }
  }

  holdOptionsMenuOpen(e: KeyboardEvent) {
    if (e.key !== 'Escape') {
      this.optionsMenuOpen = true;
    }
  }

  handleSelect(option: string | number | null) {
    // Fires when the user selects an option
    // @arg Value of a selected option
    this.$emit('input', option);
    this.toggleSelect();
  }
}
