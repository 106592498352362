

















import Vue, { PropType } from 'vue';

interface SelectItem {
  text: string,
  value: string | number,
}

/**
 * @group Base Components
 * Base select (v-select wrapper)
 */
export default Vue.extend({
  functional: true,
  name: 'VBaseSelect',
  props: {
    // Select placeholder text
    placeholder: {
      type: String,
    },
    // Disable select
    disabled: {
      type: Boolean,
      default: false
    },
    // Toggle icon
    appendIcon: {
      type: String,
    },
    // Select options
    items: {
      // Array<{ text: string, value: string \| number }>
      type: Array as PropType<Array<SelectItem>>,
      required: true
    },
    // Selected option's value
    value: {
      type: [String, Number],
      required: true
    },
    attach: {
      type: [Boolean, String],
      required: false,
    }
  }
});
