
import Vue from 'vue';
import AsyncLoading from '@/components/base/AsyncLoading.vue';
import AsyncError from '@/components/base/AsyncError.vue';

const control: any = () => ({
  component: import('./ControlTab/ControlTab.vue'),
  loading: AsyncLoading,
  error: AsyncError,
  delay: 0,
  timeout: 3000
});

/**
 * @group Control Card Group
 * Content of the currently selected Control Card tab
 */
export default Vue.extend({
  name: 'TheControlCardContent',
  functional: true,
  props: {
    // currently selected tab
    selectedTab: {
      type: String, // TODO use existing enum
      required: true
    }
  },
  render(createElement) {
    // const content = selectedTab === 'control' ? control : .....;

    return createElement(control);
  }
});
