import { MutationTree } from 'vuex';
import { GraphsState, GraphsAction } from './types';
import { Models } from '@mtap-smartcity/api';

export const mutations: MutationTree<GraphsState> = {
  [`${GraphsAction.FetchGraphs}Handler`](state, graphs: Array<Models.ScenarioGraphs.Model>): void {
    state.graphs = graphs;
  },

  [`${GraphsAction.CreateGraph}Handler`](state, newGraphData: Models.ScenarioGraphs.Model): void {
    state.graphs.push(newGraphData);
  },

  [`${GraphsAction.DeleteGraph}Handler`](state, uuid: string): void {
    // const index = state.graphs.findIndex((g) => g.uuid === uuid);
    // if (index !== -1) {
    //   state.graphs.splice(index, 1);
    // }
    state.graphs = state.graphs.filter((graph) => graph.uuid !== uuid);
  },

  [`${GraphsAction.UpdateGraph}Handler`](state, graphData): void {
    const index = state.graphs.findIndex((g) => g.id === graphData.id);
    if (index !== -1) {
      state.graphs.splice(index, 1, graphData);
    }
  }
};
