import { Module } from 'vuex';
import { TelemetryState } from './types';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';
import { RootState } from '@/store/types';

const state: TelemetryState = {
  telemetries: [],
  aggregatedTelemetry: null,
  lastTelemetries: [],
  selectedReading: null,
  energyConsumption: 0,
  co2Emission: 0,
  energyUsage: 0,
};

const namespaced = true;

export const telemetry: Module<TelemetryState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
