import { Module } from 'vuex';
import { AdminState } from './types';
// import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';
import { getters } from './getters';
import { RootState } from '@/store/types';
import { Models } from '@mtap-smartcity/api';

const state: AdminState = {
  // user: {} as Models.Bff.Auth.Users.Model,
  user: {} as Models.Bff.Auth.Users.Model,
  users: [],
  selectedUser: null,
  groups: [],
  selectedGroup: null,
  availableRoles: [],
  permissions: [],
  createUserFlag: false,
  deleteUserFlag: false
};

const namespaced = true;

export const admin: Module<AdminState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
