import { Module } from 'vuex';
import { DevicesState } from './types';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';
import { RootState } from '@/store/types';

const state: DevicesState = {
  // markers: [],
  devices: [],
  selectedMarkerId: null,
  selectedId: null,
  selectedDeviceUuid: null,
  insidePolygonMarkers: [],
  devicesPullingTimeoutID: null,
  uploadCsvFile: new FormData(),
};

const namespaced = true;

export const devices: Module<DevicesState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
