
import Vue, { PropType } from 'vue';

interface NavTab {
  name: string,
  value: string
}

/**
 * @group Control Card Group
 * Control Card's vertical tabs
 */
export default Vue.extend({
  name: 'TheControlCardTabs',
  functional: true,
  props: {
    // available tabs
    tabs: {
      // Array<{ name: string \| value: string }>
      type: Array as PropType<NavTab[]>,
      required: true
    },
    // currently selected tab
    selectedTab: {
      type: String,
      required: true
    }
  },
  render(createElement, { props: { selectedTab, tabs }, listeners }) {
    // create list of tabs
    const tabElements = tabs.map((tab) => createElement('div', {
      class: [
        'c-vertical-tab',
        { 'c-vertical-tab--active': tab.value === selectedTab }
      ],
      on: {
        click: () => {
          const emitTabSelection = listeners['tab-select'] as (payload: NavTab['value']) => void;
          emitTabSelection(tab.value);
        }
      },
      attrs: {
        role: 'tab'
      }
    }, [createElement('span', { class: ['c-vertical-tab__title'] }, `${tab.name}`)]));
    // create the nav menu tabs
    return createElement('div', {
      class: 'l-the-control-card-tabs',
      attrs: { role: 'tablist' }
    }, [tabElements]);
  }
});
