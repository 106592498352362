import { MutationTree } from 'vuex';
import { CircuitsState, CircuitsAction } from './types';
import { Models } from '@mtap-smartcity/api';

export const mutations: MutationTree<CircuitsState> = {
  [`${CircuitsAction.FetchCircuits}Handler`](state, circuits: Array<Models.Circuits.Model>): void {
    state.circuits = circuits;
  },

  [`${CircuitsAction.AddCircuitPlaceholder}Handler`](state): void {
    state.circuits = [{
      id: undefined,
      color: '#FF0000',
      name: '',
      group_id: null,
      version: 0
    }, ...state.circuits];
  },

  [`${CircuitsAction.RemoveCircuitPlaceholder}Handler`](state): void {
    const placeholderCircuitIndex = state.circuits.findIndex((circuit) => !circuit.id);
    if (placeholderCircuitIndex !== -1) {
      state.circuits.splice(placeholderCircuitIndex, 1);
    }
  },

  [`${CircuitsAction.CreateCircuit}Handler`](state, object: Models.Circuits.Model): void {
    state.circuits.push(object);
  },

  [`${CircuitsAction.UpdateCircuit}Handler`](state, update: Models.Circuits.Model): void {
    const circuitIndex = state.circuits.findIndex((circuit) => circuit.uuid === update.uuid);
    state.circuits.splice(circuitIndex, 1, update);
  },

  [`${CircuitsAction.DeleteCircuit}Handler`](state, uuid: string): void {
    state.circuits = state.circuits.filter((circuit) => circuit.uuid !== uuid);
  },

  [`${CircuitsAction.SetSelectedCircuitID}Handler`](state, id: number): void {
    state.selectedCircuitID = id;
  },

  [`${CircuitsAction.SetSelectedCircuitUuid}Handler`](state, uuid: string): void {
    state.selectedCircuitUuid = uuid;
  },

  [`${CircuitsAction.AddCircuitToGroup}Handler`](state, {
    uuid,
    groupId
  }: { uuid: string, groupId: string | null }): void {
    const updatedCircuitIndex = state.circuits.findIndex((c) => c.uuid === uuid);
    state.circuits[updatedCircuitIndex].group_id = groupId;
  },
};
