enum AnalyticsTabs {
  Telemetry = 'telemetry',
  Statistics = 'statistics',
  Analytics = 'analytics',
  Alerts = 'alerts',
  Sensors = 'sensors',
  None = 'none'
}

type AnalyticsTabsTypes =
  'telemetry'
  | 'statistics'
  | 'analytics'
  | 'alerts'
  | 'sensors'
  | 'none';

enum CreatorTabs {
  Circuits = 'circuits',
  Groups = 'groups',
}

type CreatorTabsTypes = 'circuits' | 'groups';

export {
  AnalyticsTabs,
  AnalyticsTabsTypes,
  CreatorTabs,
  CreatorTabsTypes
};
