




















import {
  Component, Vue, Prop
} from 'vue-property-decorator';

@Component
/**
 * @group Base Components
 * Custom rounded checkbox
 */
export default class RoundCheckbox extends Vue {
  // Checkbox 'checked' attribute value
  @Prop({ type: Boolean, required: true }) readonly isChecked!: boolean

  // Disable user input
  @Prop({ type: Boolean, default: false }) readonly disabled!: boolean

  // Show pulsing animation
  @Prop({ type: Boolean, default: false }) readonly loading!: boolean
}
