import { Submission } from '@/types/submissions';

interface SubmissionsState {
  submissions: Array<Submission>,
}

enum SubmissionsGetter {
  GetSubmission = 'GET_SUBMISSION',
  GetAllSubmissions = 'GET_ALL_SUBMISSIONS',
  GetFilteredSubmissions = 'GET_FILTERED_SUBMISSIONS',
}

type SubmissionsGetterType = {
  [SubmissionsGetter.GetSubmission]: (submissionID: number) => Submission | undefined,
  [SubmissionsGetter.GetAllSubmissions]: () => Submission[] | undefined,
  [SubmissionsGetter.GetFilteredSubmissions]: (submissionSTATUS: string) => Submission[] | undefined,
}

enum SubmissionsAction {
  CreateSubmission='CREATE_SUBMISSION',
  UpdateSubmission='UPDATE_SUBMISSION',
  DeleteSubmission='DELETE_SUBMISSION',
  UpdateSelectedSubmissionSTATUS='UPDATE_SELECTED_SUBMISSION_STATUS',
}

type SubmissionsActionType = {
  [SubmissionsAction.CreateSubmission]: (payload: Submission) => void,
  [SubmissionsAction.UpdateSubmission]: (payload: Submission) => void,
  [SubmissionsAction.DeleteSubmission]: (id: number) => void,
  [SubmissionsAction.UpdateSelectedSubmissionSTATUS]: ({ state, commit }, { id, newStatus }: { id: number, newStatus: string }) => void,
}

export {
  SubmissionsState,
  SubmissionsAction,
  SubmissionsActionType,
  SubmissionsGetter,
  SubmissionsGetterType
};
