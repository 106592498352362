import { Module } from 'vuex';
import { AppState } from './types';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';
import { RootState } from '@/store/types';

const state: AppState = {
  toggleProfile: false,
};

export const profile: Module<AppState, RootState> = {
  state,
  getters,
  actions,
  mutations,
};
