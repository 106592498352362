var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"c-profile-info"},[_c('p',{on:{"click":_vm.profileCard,"mouseenter":function($event){_vm.showHint = true},"mouseleave":function($event){_vm.showHint = false}}},[_c('v-avatar',{attrs:{"size":"48"}},[_c('img',{attrs:{"src":"https://static.thenounproject.com/png/363640-200.png","alt":"Avatar"}})]),_c('v-subheader',{class:{
      'c-profile-info__username': true,
      'c-profile-info__username--show': _vm.showProfile
    }},[_vm._v(" "+_vm._s(_vm.$t('messages.welcome'))+" "+_vm._s(_vm.$store.getters['admin/GET_USER'].username)+"! "),_c('span',{class:{
      'c-profile-info__hint': true,
      'c-profile-info__hint--show': _vm.showProfile && _vm.showHint
    }},[_vm._v(_vm._s(_vm.$t('main.userProfile')))])])],1),_c('v-btn',{class:{
    'c-profile-info__logout': true,
    'c-profile-info__logout--show': _vm.showProfile
    },attrs:{"rounded":"","text":"","color":"primary"},on:{"click":_vm.logout}},[_vm._v(" "+_vm._s(_vm.$t('actions.logout'))+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }