
import Vue, { PropType } from 'vue';
import GmapCustomMarker from 'vue2-gmap-custom-marker';
import LampMarker from './MapMarkers/MapMarkerLamp.vue';
import LampMarkerAlert from './MapMarkers/MapMarkerLampAlert.vue';
import GatewayMarker from './MapMarkers/MapMarkerGateway.vue';
import SensorMarker from './MapMarkers/MapMarkerSensor.vue';
import ControlCabinetMarker from './MapMarkers/MapMarkerControlCabinet.vue';
import UserMarker from './MapMarkers/MapMarkerUser.vue';

interface Coordinates {
  lat: number,
  lng: number
}

export default Vue.extend({
  functional: true,
  name: 'MapMarker',
  props: {
    coordinates: {
      type: Object as PropType<Coordinates>,
      required: true
    },
    isSelected: {
      type: Boolean,
      default: false
    },
    isConnected: {
      type: Boolean,
      default: false
    },
    isError: {
      type: Boolean,
      default: false
    },
    isControlCabinet: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
    },
    type: {
      type: String as PropType<'lamp' | 'gateway' | 'sensor' | 'control-cabinet' | 'user'>,
      required: true
    },
    name: {
      type: String,
    },
    mapType: {
      type: String,
    },
    translatedType: {
      type: String,
    },
  },
  render(createElement, { props, listeners }) {
    function deviceMarkerIcon() {
      if (props.type === 'lamp') {
        return props.isError ? LampMarkerAlert : LampMarker;
      }
      if (props.type === 'gateway' && props.isControlCabinet) return ControlCabinetMarker;
      if (props.type === 'gateway' && !props.isControlCabinet) return GatewayMarker;
      if (props.type === 'user') return UserMarker;
      return SensorMarker;
    }

    const markerIcon = createElement(deviceMarkerIcon(), {
      props: {
        color: props.color,
        isSelected: props.isSelected,
        isConnected: props.isConnected,
        type: props.translatedType,
        name: props.name,
        mapType: props.mapType,
      }
    });

    return createElement(GmapCustomMarker, {
      class: 'c-map-marker',
      props: {
        marker: props.coordinates,
      },
      nativeOn: {
        contextmenu(event: Event) {
          event.preventDefault();
          event.stopPropagation();
        },
        click(event: Event) {
          (listeners.click as (payload?: any) => void)(event);
        }
      }
    }, [markerIcon]);
  }
});
