var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"l-nav-drawer-wrapper"},[_c('v-navigation-drawer',{staticClass:"c-nav-drawer elevation-0 d-flex flex-column",attrs:{"absolute":"","floating":"","permanent":"","mini-variant":!_vm.isExpanded,"color":"#cfcfcf","width":_vm.drawerDimensions.drawerWidth,"mini-variant-width":_vm.drawerDimensions.drawerCollapsedWidth},nativeOn:{"mouseover":function($event){return _vm.handleHover(true)},"mouseleave":function($event){return _vm.handleHover(false)}},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('div',{staticClass:"c-nav-drawer__profile"},[_c('TheNavDrawerProfileInfo',{attrs:{"showProfile":_vm.isExpanded}})],1)]},proxy:true},{key:"append",fn:function(){return [_c('div',{class:{
        'c-nav-drawer__language': true,
        'c-nav-drawer__language--show': _vm.isExpanded
      }},[_c('div',{staticClass:"c-nav-drawer__logo"},[_c('img',{attrs:{"src":require("@/assets/powered.svg")}})]),_c('LanguageSwitch')],1)]},proxy:true}])},[_c('div',{staticClass:"c-nav-drawer__links"},[_c('TheNavDrawerLinks')],1)]),_c('TheNavDrawerFloatingLogo',{attrs:{"isExpanded":_vm.isExpanded}}),_c('div',{class:{
    'l-nav-drawer-wrapper__corner': true,
    'l-nav-drawer-wrapper__corner--expanded': _vm.isExpanded
    }},[_c('div',{staticClass:"l-nav-drawer-wrapper__corner-inside"})]),_c('div',{class:{
    'l-nav-drawer-wrapper__corner': true,
    'l-nav-drawer-wrapper__corner--bottom': true,
    'l-nav-drawer-wrapper__corner--expanded': _vm.isExpanded}},[_c('div',{staticClass:"l-nav-drawer-wrapper__corner-inside"})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }