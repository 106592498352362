import { ActionTree } from 'vuex';
import { CircuitsState, CircuitsAction } from './types';
import { RootState } from '@/store/types';
import { Models } from '@mtap-smartcity/api';
import { userActionDispatcher } from '../app/actions';
import { DevicesAction, DevicesGetter, DevicesGetterType } from '../devices/types';
import {
  fetchCircuits, createCircuit, updateCircuit, deleteCircuit, addCircuitToScenario, overwriteCircuitSettings, addCircuitToGroup
} from '@/api/circuits';

export const actions: ActionTree<CircuitsState, RootState> = {
  [CircuitsAction.FetchCircuits](
    { commit },
    { unixTime = null }: { unixTime: number | null } = { unixTime: null }
  ): Promise<void> {
    return fetchCircuits(unixTime)
      .then((circuits: Array<Models.Circuits.Model>) => commit(`${CircuitsAction.FetchCircuits}Handler`, circuits));
  },

  [CircuitsAction.SetSelectedCircuitID]({ commit }, id: number | null): void {
    commit(`${CircuitsAction.SetSelectedCircuitID}Handler`, id);
  },

  [CircuitsAction.SetSelectedCircuitUuid]({ commit }, uuid: string | null): void {
    commit(`${CircuitsAction.SetSelectedCircuitUuid}Handler`, uuid);
  },

  [CircuitsAction.AddCircuitPlaceholder]({ commit }): void {
    commit(`${CircuitsAction.AddCircuitPlaceholder}Handler`);
  },

  [CircuitsAction.RemoveCircuitPlaceholder]({ commit }): void {
    commit(`${CircuitsAction.RemoveCircuitPlaceholder}Handler`);
  },

  [CircuitsAction.CreateCircuit]({ commit, dispatch, rootState }, circuit: Models.Circuits.Model): Promise<void> {
    userActionDispatcher(dispatch, (this as any).$app.$t('messages.creatingCircuit'), 'pending');
    return createCircuit(circuit)
      .then(async (item) => {
        commit(`${CircuitsAction.CreateCircuit}Handler`, item);
        const selectedMarkers = (rootState as any).devices.insidePolygonMarkers;
        // eslint-disable-next-line no-restricted-syntax
        for (const m of selectedMarkers) {
          // eslint-disable-next-line no-await-in-loop
          await dispatch(`devices/${DevicesAction.AddDeviceToCircuit}`, {
            circuitId: item.uuid,
            uuid: m.uuid,
            controllerType: m.controller_type
          }, { root: true });
        }
        dispatch(`devices/${DevicesAction.SetInsidePolygonMarkers}`, [], { root: true });
        userActionDispatcher(dispatch, '', 'success');
      })
      .catch((err) => {
        userActionDispatcher(dispatch, (this as any).$app.$t('messages.error'), 'error');
        throw err;
      });
  },

  [CircuitsAction.UpdateCircuit]({ dispatch, commit }, circuit: Models.Circuits.Model): Promise<void> {
    userActionDispatcher(dispatch, (this as any).$app.$t('messages.updatingCircuit'), 'pending');
    return updateCircuit(circuit)
      .then((item) => {
        userActionDispatcher(dispatch, '', 'success');
        commit(`${CircuitsAction.UpdateCircuit}Handler`, item);
      })
      .catch((err) => {
        userActionDispatcher(dispatch, (this as any).$app.$t('messages.error'), 'error');
        throw err;
      });
  },

  [CircuitsAction.DeleteCircuit]({ dispatch, commit, rootGetters }, uuid: string): Promise<void> {
    userActionDispatcher(dispatch, (this as any).$app.$t('messages.deletingCircuit'), 'pending');
    return deleteCircuit(uuid)
      .then(() => {
        const getCircuitDevices: DevicesGetterType['GET_CIRCUIT_DEVICES'] = (rootGetters as any)[`devices/${DevicesGetter.GetCircuitDevices}`];
        const circuitDevices = getCircuitDevices(uuid);
        circuitDevices.forEach((device) => {
          commit(`devices/${DevicesAction.AddDeviceToCircuit}Handler`, {
            ...device,
            circuitId: null,
          }, { root: true });
        });
        userActionDispatcher(dispatch, '', 'success');
        commit(`${CircuitsAction.DeleteCircuit}Handler`, uuid);
      })
      .catch(() => {
        userActionDispatcher(dispatch, (this as any).$app.$t('messages.error'), 'error');
      });
  },

  [CircuitsAction.AddCircuitToGroup]({ commit, dispatch }, { uuid, groupId }: { uuid: string, groupId: string | null }): Promise<void> {
    if (uuid) {
      userActionDispatcher(dispatch, (this as any).$app.$t('messages.addingCircuitToGroup[0]'), 'pending');
    } else {
      userActionDispatcher(dispatch, (this as any).$app.$t('messages.addingCircuitToGroup[1]'), 'pending');
    }
    return addCircuitToGroup(uuid, groupId)
      .then((response) => {
        if (response) {
          userActionDispatcher(dispatch, '', 'success');
          commit(`${CircuitsAction.AddCircuitToGroup}Handler`, { uuid, groupId });
          return;
        }
        userActionDispatcher(dispatch, (this as any).$app.$t('messages.error'), 'error');
        throw new Error('Error adding circuit to group');
      })
      .catch(() => {
        userActionDispatcher(dispatch, (this as any).$app.$t('messages.error'), 'error');
      });
  },

  [CircuitsAction.AddCircuitToScenario](
    { dispatch, commit },
    {
      circuit,
      scenarioName,
      assignToDefault
    }: {
      circuit: Models.Circuits.Model,
      scenarioName: string | null
      assignToDefault: boolean
    }
  ): Promise<void> {
    const message = assignToDefault ? 'messages.detachingCircuitFromScenario' : 'messages.assigningCircuitToScenario';
    userActionDispatcher(dispatch, (this as any).$app.$t(message, { scenarioName }), 'pending');
    return addCircuitToScenario(circuit)
      .then(() => {
        userActionDispatcher(dispatch, '', 'success');
        commit(`${CircuitsAction.UpdateCircuit}Handler`, circuit);
      })
      .catch(() => {
        userActionDispatcher(dispatch, (this as any).$app.$t('messages.error'), 'error');
      });
  },

  [CircuitsAction.OverwriteCircuitSettings]({ dispatch, commit }, circuit: Models.Circuits.Model): Promise<Models.Circuits.Model> {
    userActionDispatcher(dispatch, (this as any).$app.$t('messages.sendingOverwrite'), 'pending');
    return overwriteCircuitSettings(circuit)
      .then(() => {
        userActionDispatcher(dispatch, '', 'success');
        commit(`${CircuitsAction.UpdateCircuit}Handler`, circuit);
        return circuit;
      })
      .catch((err) => {
        userActionDispatcher(dispatch, (this as any).$app.$t('messages.error'), 'error');
        throw err;
      });
  }
};
