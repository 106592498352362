import { MutationTree } from 'vuex';
import { TelemetryAction, TelemetryState } from './types';
import { Models } from '@mtap-smartcity/api';

export const mutations: MutationTree<TelemetryState> = {
  [`${TelemetryAction.FetchDeviceTelemetry}Handler`](state, telemetry: Models.Telemetries.Telemetry): void {
    const { object_id } = telemetry;
    let indexOfTelemetry: number;
    if (state.lastTelemetries.length) {
      indexOfTelemetry = state.lastTelemetries.findIndex((t) => t.object_id === object_id);
      if (indexOfTelemetry === -1) {
        state.lastTelemetries.push(telemetry);
      } else {
        state.lastTelemetries.splice(indexOfTelemetry, 1, telemetry);
      }
    } else {
      state.lastTelemetries.push(telemetry);
    }
  },

  [`${TelemetryAction.FetchLastTelemetriesFrom}Handler`](state, telemetries: Array<Models.Telemetries.Telemetry>): void {
    const merged: Models.Telemetries.Telemetry[] = [];
    for (let i = 0; i < telemetries.length; i += 1) {
      merged.push({
        ...(state.lastTelemetries.find((telemetry) => telemetry.object_id === telemetries[i].object_id)),
        ...telemetries[i]
      });
    }
    state.lastTelemetries = merged;
  },

  [`${TelemetryAction.FetchDeviceTelemetriesArray}Handler`](state, telemetries: Array<Models.Telemetries.Telemetry>): void {
    state.telemetries = telemetries;
  },

  [`${TelemetryAction.FetchDeviceTelemetriesFromTo}Handler`](state, telemetries: Array<Models.Telemetries.Telemetry>): void {
    state.telemetries = telemetries;
  },

  [`${TelemetryAction.FetchCircuitTelemetriesFromTo}Handler`](state, sumOfTelemetry: Models.Telemetries.SumOfTelemetry): void {
    state.aggregatedTelemetry = sumOfTelemetry;
  },

  [`${TelemetryAction.FetchGroupTelemetriesFromTo}Handler`](state, sumOfTelemetry: Models.Telemetries.SumOfTelemetry): void {
    state.aggregatedTelemetry = sumOfTelemetry;
  },

  [`${TelemetryAction.FetchEnergyUsedFromTo}Handler`](state, energyUsed: number): void {
    state.energyUsage = energyUsed;
  },

  [`${TelemetryAction.SetSelectedReading}Handler`](state, reading: Models.Telemetries.Telemetry | null): void {
    state.selectedReading = reading;
  },

  [`${TelemetryAction.FetchDeviceKobize}Handler`](state, kobize: Models.Analytics.Kobize.Model | null): void {
    if (!kobize || !kobize.co2_emission || !kobize.total_energy) {
      state.co2Emission = 0;
      state.energyConsumption = 0;
    } else {
      state.co2Emission = kobize.co2_emission;
      state.energyConsumption = kobize.total_energy;
    }
  },

  [`${TelemetryAction.FetchCircuitKobize}Handler`](state, sumOfKobize: Models.Analytics.Kobize.SumOfKobize | null): void {
    if (!sumOfKobize || !sumOfKobize.co2Emission || !sumOfKobize.energyConsumption) {
      state.co2Emission = 0;
      state.energyConsumption = 0;
    } else {
      state.co2Emission = sumOfKobize.co2Emission;
      state.energyConsumption = sumOfKobize.energyConsumption;
    }
  },

  [`${TelemetryAction.FetchGroupKobize}Handler`](state, sumOfKobize: Models.Analytics.Kobize.SumOfKobize | null): void {
    if (!sumOfKobize || !sumOfKobize.co2Emission || !sumOfKobize.energyConsumption) {
      state.co2Emission = 0;
      state.energyConsumption = 0;
    } else {
      state.co2Emission = sumOfKobize.co2Emission;
      state.energyConsumption = sumOfKobize.energyConsumption;
    }
  },
};
