













import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { AppAction, AppActionType, AppState } from '@/store/modules/app/types';
import { DevicesAction, DevicesActionType, DevicesState } from '@/store/modules/devices/types';
import TheControlCardNavTabs from './TheControlCardTabs/TheControlCardTabs.vue';
import TheControlCardContent from './TheControlCardContent/TheControlCardContent.vue';
import { CircuitsAction, CircuitsActionType } from '@/store/modules/circuits/types';
import { GroupsAction, GroupsActionType } from '@/store/modules/groups/types';

const app = namespace('app');
const devices = namespace('devices');
const circuits = namespace('circuits');
const groups = namespace('groups');

enum ControlCardTab {
  Control = 'control',
  Recent = 'recent',
  Status = 'status',
}

@Component({
  name: 'TheControlCard',
  components: {
    TheControlCardNavTabs,
    TheControlCardContent,
  },
})
/**
 * @vuese
 * @group Control Card Group
 * The right hand side drawer (currently only used for device/circuit/group overwrites)
 */
export default class TheControlCard extends Vue {
  controlCardSelectedTab: ControlCardTab = ControlCardTab.Control;

  isOpen = false;

  get tabs() {
    return [
      { name: this.$t('controlCardTabs[0]'), value: ControlCardTab.Control },
      // { name: this.$t('controlCardTabs[1]'), value: ControlCardTab.Recent },
      // { name: this.$t('controlCardTabs[2]'), value: ControlCardTab.Status },
    ];
  }

  @app.State
  selectedTab!: AppState['selectedTab'];

  @app.State
  mapSelectionEnabled!: AppState['mapSelectionEnabled'];

  @devices.State
  selectedMarkerId!: DevicesState['selectedMarkerId'];

  @app.Action(AppAction.SetSelectedTab)
  setSelectedTab!: AppActionType['SET_SELECTED_TAB']

  @devices.Action(DevicesAction.SetSelectedId)
  setSelectedId!: DevicesActionType['SET_SELECTED_ID'];

  @devices.Action(DevicesAction.SetSelectedMarkerId)
  setSelectedMarkerId!: DevicesActionType['SET_SELECTED_MARKER_ID'];

  @circuits.Action(CircuitsAction.SetSelectedCircuitID)
  setSelectedCircuitID!: CircuitsActionType['SET_SELECTED_CIRCUIT_ID'];

  @groups.Action(GroupsAction.SetSelectedGroupID)
  setSelectedGroupID!: GroupsActionType['SET_SELECTED_GROUP_ID'];

  // opens the drawer when the user selects a lamp, by clicking on a lamp's map marker and chooses Control from the list
  @Watch('selectedMarkerId')
  onSelectedMarkerIdChange(newValue: number | null) {
    if (newValue && !this.selectedTab) this.isOpen = true;
  }

  // clears lamp selection when the drawer is being closed
  @Watch('isOpen')
  onIsOpenChange(newValue: boolean) {
    if (!newValue) {
      this.setSelectedMarkerId(null);
    }
  }

  // closes the drawer when one of the left hand side drawer's tabs is being selected,
  // i.e. when the main card component is being displayed
  @Watch('selectedTab')
  onSelectedTabChange(newValue: string) {
    if (newValue !== null) this.isOpen = false;
  }

  selectTab(tab: ControlCardTab) {
    //  disable tab selection when the user is creating circuits on the map
    if (this.mapSelectionEnabled) return;

    this.controlCardSelectedTab = tab;
    // close the main card if it's open
    this.setSelectedTab(null);

    // toggle the drawer isOpen state when user clicks on currently active tab
    if (tab === this.controlCardSelectedTab) {
      this.isOpen = !this.isOpen;
      // if it had been closed by clicking on drawer reset selectedDevice, selectedCircuit and selectedGroup
      if (!this.isOpen) {
        this.setSelectedId(null);
        this.setSelectedCircuitID(null);
        this.setSelectedGroupID(null);
      }
    } else {
      // open the drawer if it's been closed
      this.isOpen = true;
    }
  }
}
