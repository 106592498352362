import { Models } from '@mtap-smartcity/api';

interface GroupsState {
  groups: Array<Models.Groups.Model>;
  selectedGroupID: number | null;
  selectedGroupUuid: string | null;
}

enum GroupsGetter {
  GetGroup = 'GET_GROUP',
  GetGroupById = 'GET_GROUP_BY_ID',
}

type GroupsGetterType = {
  [GroupsGetter.GetGroup]: (uuid: string) => Models.Groups.Model | undefined,
  [GroupsGetter.GetGroupById]: (id: number) => Models.Groups.Model | undefined,
}

enum GroupsAction {
  SetSelectedGroupID = 'SET_SELECTED_GROUP_ID',
  SetSelectedGroupUuid = 'SET_SELECTED_GROUP_UUID',
  AddGroupPlaceholder = 'ADD_GROUP_PLACEHOLDER',
  RemoveGroupPlaceholder = 'REMOVE_GROUP_PLACEHOLDER',
  AddGroupToScenario = 'ADD_GROUP_TO_SCENARIO',
  OverwriteGroupSettings = 'OVERWRITE_GROUP_SETTINGS',
  FetchGroups = 'FETCH_GROUPS',
  CreateGroup = 'CREATE_GROUP',
  UpdateGroup = 'UPDATE_GROUP',
  DeleteGroup = 'DELETE_GROUP',
}

type GroupsActionType = {
  [GroupsAction.FetchGroups]: (payload?: { unixTime: number }) => Promise<void>,
  [GroupsAction.SetSelectedGroupID]: (id: number | null) => void,
  [GroupsAction.SetSelectedGroupUuid]: (uuid: string | null) => void,
  [GroupsAction.AddGroupPlaceholder]: () => void,
  [GroupsAction.RemoveGroupPlaceholder]: () => void,
  [GroupsAction.CreateGroup]: (group: Models.Groups.Model) => Promise<void>,
  [GroupsAction.UpdateGroup]: (group: Models.Groups.Model) => Promise<void>,
  [GroupsAction.DeleteGroup]: (uuid: string) => Promise<void>,
  [GroupsAction.AddGroupToScenario]: ({
    group,
    scenarioName,
    assignToDefault
  }: {
    group: Models.Groups.Model,
    scenarioName: string | null
    assignToDefault: boolean
  }) => Promise<void>,
  [GroupsAction.OverwriteGroupSettings]: (group: Models.Groups.Model) => Promise<void>,
}

export {
  GroupsState,
  GroupsGetter,
  GroupsGetterType,
  GroupsAction,
  GroupsActionType
};
