import Vue from 'vue';
import Vuex, { StoreOptions, Store } from 'vuex';
import { RootState } from './types';
import { app } from './modules/app';
import { circuits } from './modules/circuits';
import { groups } from './modules/groups';
import { devices } from './modules/devices';
import { scenarios } from './modules/scenarios';
import { graphs } from './modules/graphs';
import { admin } from './modules/admin';
import { profile } from './modules/profile';
import { telemetry } from './modules/telemetry';
import { submissions } from './modules/submissions';

Vue.use(Vuex);

const store: StoreOptions<RootState> = {
  modules: {
    app,
    circuits,
    devices,
    scenarios,
    graphs,
    groups,
    admin,
    profile,
    telemetry,
    submissions,
  }
};

interface AppStore<T> extends Store<T> {
  $app?: any;
}

const appStore: AppStore<RootState> = new Vuex.Store<RootState>(store);

export default appStore;

export { AppStore };
