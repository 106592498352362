import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '@/views/Home.vue';
import getI18n from '@/plugins/i18n';
import { app } from '@/store/modules/app';
import { RuntimeConfig } from '@/types/config';

Vue.use(VueRouter);

const getRoutes = async () => {
  const runtimeConfig = await app.state!['runtimeConfig']! as RuntimeConfig;
  const i18n = await getI18n();
  return [
    {
      path: '/:locale',
      name: 'Home',
      component: Home,
      beforeEnter: (to, from, next) => {
        const { locale } = to.params;
        const supportedLocales = runtimeConfig.VUE_APP_I18N_SUPPORTED_LOCALE.split(',');
        if (!supportedLocales.includes(locale)) return next('pl');
        if (i18n.locale !== locale) {
          i18n.locale = locale;
        }
        return next();
      }
    },
    {
      path: '*',
      redirect() {
        return runtimeConfig.VUE_APP_I18N_LOCALE;
      }
    }
  ];
};

const router = async () => {
  const routes = await getRoutes();
  return new VueRouter({
    routes
  });
};

export default router;
