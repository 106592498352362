


































import {
  Component,
  Vue,
  Prop,
} from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import {
  AdminAction, AdminActionType,
} from '@/store/modules/admin/types';
import { AppGetter } from '@/store/modules/app/types';
import { getLoggedUserId } from '@/utils/helpers';

const admin = namespace('admin');

@Component
/**
 * @group The NavDrawer
 * Main navigation drawer profile info
 */
export default class TheNavDrawerProfileInfo extends Vue {
  @Prop(Boolean) readonly showProfile!: boolean;

  showHint = false;

  @admin.Action(AdminAction.FetchUser)
  fetchUser!: AdminActionType['FETCH_USER'];

  logout() {
    this.$keycloak.keycloak.logout();
  }

  profileCard() {
    this.$store.dispatch('toggleProfile');
    this.$store.dispatch('app/SET_SELECTED_TAB', null);
  }

  async mounted() {
    const token = this.$store.getters[`app/${AppGetter.GetToken}`];
    const userId = getLoggedUserId(token);
    if (!userId) return;
    await this.fetchUser(userId);
  }
}
