import Keycloak from 'keycloak-js';
import { KeycloakConfig, RuntimeConfig } from '../types/config';
import { app } from '@/store/modules/app';

export default async () => {
  const runtimeConfig = await app.state!['runtimeConfig']! as RuntimeConfig;
  const config: KeycloakConfig = {
    url: runtimeConfig.VUE_APP_KEYCLOAK_URL,
    realm: runtimeConfig.VUE_APP_KEYCLOAK_REALM,
    clientId: runtimeConfig.VUE_APP_KEYCLOAK_CLIENT_ID,
    onLoad: runtimeConfig.VUE_APP_KEYCLOAK_ONLOAD,
  };
  return {
    keycloak: Keycloak(config),
    config
  };
};
