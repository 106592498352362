




















import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class BaseTable extends Vue {
  @Prop({ type: Array, required: true }) readonly tableData!: Array<object>

  @Prop({ type: String, default: 'id' }) readonly rowKey!: string

  @Prop({ type: Boolean, default: false }) readonly disabled!: boolean
}
