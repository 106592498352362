import { Models } from '@mtap-smartcity/api';

interface DevicesState {
  devices: Array<Models.Devices.Device>,
  selectedMarkerId: null | number,
  selectedId: null | number,
  selectedDeviceUuid: null | string,
  insidePolygonMarkers: Array<Models.Devices.Device>,
  devicesPullingTimeoutID: null | number,
  uploadCsvFile: FormData
}

enum DevicesGetter {
  GetDevice = 'GET_DEVICE',
  GetDeviceById = 'GET_DEVICE_BY_ID',
  GetDeviceByObjectId = 'GET_DEVICE_BY_OBJECT_ID',
  GetSelectedMarker = 'GET_SELECTED_MARKER',
  GetCircuitDevices = 'GET_CIRCUIT_DEVICES',
  GetCsvResult = 'GET_CSV_RESULT'
}

type DevicesGetterType = {
  [DevicesGetter.GetDevice]: (uuid: string, deviceType?: Models.Constants.DeviceType) => Models.Devices.Device | undefined,
  [DevicesGetter.GetDeviceById]: (id: number, deviceType?: Models.Constants.DeviceType) => Models.Devices.Device | undefined,
  [DevicesGetter.GetDeviceByObjectId]: (objectId: string, deviceType?: Models.Constants.DeviceType) => Models.Devices.Device | undefined,
  [DevicesGetter.GetSelectedMarker]: Models.Devices.Device | null,
  [DevicesGetter.GetCircuitDevices]: (circuitId: string) => Array<Models.Devices.Device>,
  [DevicesGetter.GetCsvResult]: () => FormData
}

enum DevicesAction {
  FetchDevices = 'FETCH_DEVICES',
  FetchDevice = 'FETCH_DEVICE',
  AddDevicePlaceholder = 'ADD_DEVICE_PLACEHOLDER',
  RemoveDevicePlaceholder = 'REMOVE_DEVICE_PLACEHOLDER',
  CreateDevice = 'CREATE_DEVICE',
  UpdateDevice = 'UPDATE_DEVICE',
  RecommissionDevice = 'RECOMMISSION_DEVICE',
  SetSelectedMarkerId = 'SET_SELECTED_MARKER_ID',
  SetSelectedId = 'SET_SELECTED_ID',
  SetSelectedDeviceUuid = 'SET_SELECTED_DEVICE_UUID',
  SetInsidePolygonMarkers = 'SET_INSIDE_POLYGON_MARKERS',
  AddDeviceToCircuit = 'ADD_DEVICE_TO_CIRCUIT',
  AddDeviceToScenario = 'ADD_DEVICE_TO_SCENARIO',
  OverwriteDeviceSettings = 'OVERWRITE_DEVICE_SETTINGS',
  UploadCsvFile = 'UPLOAD_CSV_FILE',
}

type DevicesActionType = {
  // [DevicesAction.FetchMarkers]: () => Promise<void>,
  [DevicesAction.FetchDevices]: (payload?: {unixTime?: number | null, deviceType?: Models.Constants.DeviceType}) => Promise<void>,
  [DevicesAction.FetchDevice]: (payload?: {device?: Models.Devices.Device, unixTime?: number | null}) => Promise<void>,
  [DevicesAction.AddDevicePlaceholder]: (payload: { type: Models.Constants.DeviceType, coordinates?: google.maps.LatLngLiteral }) => void,
  [DevicesAction.RemoveDevicePlaceholder]: () => void,
  [DevicesAction.CreateDevice]: (device: Models.Devices.Device) => Promise<void>,
  [DevicesAction.UpdateDevice]: (device: Models.Devices.Device) => Promise<void>,
  [DevicesAction.RecommissionDevice]: (device: Models.Devices.Device) => Promise<Models.Devices.Model>,
  [DevicesAction.SetSelectedMarkerId]: (id: number | null) => void,
  [DevicesAction.SetSelectedId]: (id: number | null) => void,
  [DevicesAction.SetSelectedDeviceUuid]: (id: string | null) => void,
  [DevicesAction.SetInsidePolygonMarkers]: (markers: Array<Models.Devices.Device>) => void,
  [DevicesAction.AddDeviceToCircuit]: (payload: { uuid: string, circuitId: string | null, controllerType: Models.Constants.ControllerType}) => Promise<void>,
  [DevicesAction.AddDeviceToScenario]: ({
    device,
    scenarioName,
    assignToDefault
  }: {
    device: Models.Devices.Device,
    scenarioName: string | null
    assignToDefault: boolean
  }) => Promise<void>,
  [DevicesAction.OverwriteDeviceSettings]: (device: Models.Devices.Device) => Promise<void>,
  [DevicesAction.UploadCsvFile]: (file: FormData) => Promise<void>
}

export {
  DevicesState,
  DevicesGetter,
  DevicesGetterType,
  DevicesAction,
  DevicesActionType
};
