




























import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
/**
 * @group Base Components
 * Date picker popup with text field activator
 */
export default class DatePicker extends Vue {
  // Selected date string in YYYY-mm-dd format
  @Prop(String) readonly date!: string

  // Earliest date constraint
  @Prop(String) readonly minDate!: string

  // Latest date constraint
  @Prop(String) readonly maxDate!: string

  // Date picker label
  @Prop(String) readonly label!: string

  allowedDates(val) {
    if (this.minDate && this.maxDate) {
      return this.maxDate >= val && val >= this.minDate;
    }
    if (this.minDate) {
      return val >= this.minDate;
    }
    if (this.maxDate) {
      return val <= this.maxDate;
    }
    return val;
  }

  selectDate(date: string) {
    // Fires on date selection
    // @arg Date string in YYYY-mm-dd format
    this.$emit('dateSelect', date);
  }
}
