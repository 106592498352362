import Vue from 'vue';
import * as VueGoogleMaps from 'vue2-google-maps';
import { app } from '@/store/modules/app';
import { RuntimeConfig } from '@/types/config';

export default async () => {
  const runtimeConfig = await app.state!['runtimeConfig']! as RuntimeConfig;
  Vue.use(VueGoogleMaps, {
    load: {
      key: runtimeConfig.VUE_APP_GOOGLE_API_KEY,
      libraries: ['places', 'geometry'],
    },
  });
};
