import { Module } from 'vuex';
import { CircuitsState } from './types';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';
import { RootState } from '@/store/types';

const state: CircuitsState = {
  circuits: [],
  selectedCircuitID: null,
  selectedCircuitUuid: null
};

const namespaced = true;

export const circuits: Module<CircuitsState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
