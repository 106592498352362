import { GetterTree } from 'vuex';
import { SubmissionsState, SubmissionsGetter } from './types';
import { RootState } from '@/store/types';
import { Submission } from '@/types/submissions';

export const getters: GetterTree<SubmissionsState, RootState> = {
  [SubmissionsGetter.GetSubmission](state): (submissionID: number) => Submission | undefined {
    return (submissionID: number) => state.submissions.find((sub) => sub.id === submissionID);
  },
  [SubmissionsGetter.GetAllSubmissions](state): () => Submission[] | undefined {
    return () => state.submissions;
  },
  [SubmissionsGetter.GetFilteredSubmissions](state): (submissionSTATUS: string) => Submission[] | undefined {
    return (submissionSTATUS: string) => state.submissions.filter((sub) => sub.status === submissionSTATUS);
  },
};
