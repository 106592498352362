import { Module } from 'vuex';
import { ScenariosState } from './types';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';
import { RootState } from '@/store/types';

const state: ScenariosState = {
  scenarios: [],
  selectedScenarioID: null,
  selectedScenarioUuid: null,
  scenarioElements: [],
  selectedScenarioElementEditID: null,
  selectedScenarioHasConnections: null
};

const namespaced = true;

export const scenarios: Module<ScenariosState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
