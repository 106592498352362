import { GetterTree } from 'vuex';
import { AppState, AppGetter } from './types';
import { RootState } from '@/store/types';
import { RuntimeConfig } from '@/types/config';

export const getters: GetterTree<AppState, RootState> = {
  [AppGetter.GetToken](state): string {
    if (state.token) {
      return state.token;
    }
    state.token = localStorage.getItem('token')!;
    return state.token;
  },
  [AppGetter.GetRefreshToken](state): string {
    if (state.refreshToken) {
      return state.refreshToken;
    }
    state.refreshToken = localStorage.getItem('refresh-token')!;
    return state.refreshToken;
  },
  [AppGetter.GetRuntimeConfig](state): RuntimeConfig | null {
    if (state.runtimeConfig) { return state.runtimeConfig; }
    return null;
  }
};
