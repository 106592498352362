

























































import Vue from 'vue';

export default Vue.extend({
  name: 'EditableListItem',
  props: {
    editState: {
      type: Boolean,
      default: false,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    permissionCheckEdit: {
      type: Boolean,
      default: true,
    },
    permissionCheckDelete: {
      type: Boolean,
      default: true,
    },
    permissionCheckCreatorEdit: {
      type: Boolean,
      default: true,
    },
    permissionCheckCreatorDelete: {
      type: Boolean,
      default: true,
    }
  }
});
