import jwt_decode from 'jwt-decode';

interface IJwtDecode {
  exp: number,
  iat: number,
  sub: string,
}

export const getLoggedUserId = (token: string): string|null => {
  if (!token) return null;
  const user: IJwtDecode = jwt_decode(token);
  return user.sub;
};
