





















import {
  Component, Vue, Prop
} from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { AppState } from '@/store/modules/app/types';
import ProfileCard from '../../TheMainCard/ProfileCard/ProfileCard.vue';
import TheHistoricalParameters from '@/components/TheHistoricalParameters/HistoricalSection.vue';

const app = namespace('app');

@Component({
  components: {
    ProfileCard,
    TheHistoricalParameters
  }
})
/**
 * @group The NavDrawer
 * Main navigation drawer floating logo
 */
export default class TheNavDrawerFloatingLogo extends Vue {
  @Prop(Boolean) readonly isExpanded!: boolean

  pending = false

  @app.State
  selectedTab!: AppState['selectedTab']

  get logoSrc() {
    // eslint-disable-next-line global-require
    return require('@/assets/mtap-logo.svg');
  }
}
