<template>
  <div id="app" @dragover.prevent @drop.prevent>
    <v-app>
      <router-view />
    </v-app>
  </div>
</template>

<script>
export default {
  name: 'App',
};
</script>

<style>
@import "../node_modules/vue-datetime/dist/vue-datetime.min.css";

  html {
    overflow: auto !important;
  }
  body {
    background-color: #F0F0F0;
  }
  h1 {
    padding: 0;
    margin-top: 0;
  }
  #app {
    width: 100%;
    height: 100vh;
    margin: auto;
  }
  #navbar {
    height: 4rem;
  }
  .navbar-logo {
    height: 3rem;
  }

  .vdialognew {
    box-shadow: none !important;
  }
  .v-tooltip__content {
    z-index: 200 !important;
  }
  *,*:focus,*:hover{
    outline: none;
  }
</style>
