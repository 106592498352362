import Fetch from '@/utils/fetch';
import { Models } from '@mtap-smartcity/api';

const fetchDeviceTelemetry = ({ objectId }: {
  objectId: string
}) => Fetch<Models.Telemetries.Telemetry>(
  `/api/telemetries/last-measurement?deviceId=${objectId}`, {}
);

const fetchLastTelemetriesFrom = (queryParams: {
  attributes?: string,
  timestampFrom?: string
}) => Fetch<Array<Models.Telemetries.Telemetry>>(
  '/api/telemetries/last-measurements', { queryParams }
);

const fetchDeviceTelemetriesArray = (queryParams: {
  limit?: number, offset?: number, deviceId?: string | null, error?: number | number[]
}) => Fetch<Array<Models.Telemetries.Telemetry>>('/api/telemetries', { queryParams });

const fetchDeviceTelemetriesFromTo = (queryParams: {
  timestampFrom?: string, timestampTo?: string, deviceId?: string | null
}) => Fetch<Array<Models.Telemetries.Telemetry>>('/api/telemetries/chart', { queryParams });

const fetchCircuitTelemetriesFromTo = (
  objectId: string,
  queryParams: {
    startDate?: string,
    endDate?: string,
    timestamp_delta?: number
  }
) => Fetch<Models.Telemetries.SumOfTelemetry>(`/api/bff/telemetries/circuit/${objectId}`, { queryParams });

const fetchGroupTelemetriesFromTo = (
  objectId: string,
  queryParams: {
    startDate?: string,
    endDate?: string,
    timestamp_delta?: number
  }
) => Fetch<Models.Telemetries.SumOfTelemetry>(`/api/bff/telemetries/group/${objectId}`, { queryParams });

const fetchEnergyUsedFromTo = (queryParams: {
  timestampFrom?: string, timestampTo?: string, deviceId?: string | null
}) => Fetch<number>('/api/telemetries/cumulate', { queryParams });

const fetchDeviceKobize = (
  device: Models.Devices.Device,
  queryParams?: {
    pointInTime?: string,
    startDate?: string,
    endDate?: string
  }
) => Fetch<Models.Analytics.Kobize.Model>(`api/analytics/kobize/${device.object_id}`, { queryParams });

const fetchCircuitKobize = (
  circuit: Models.Circuits.Model,
  queryParams?: {
    startDate?: string,
    endDate?: string
  }
) => Fetch<Models.Analytics.Kobize.SumOfKobize>(`/api/bff/analytics/kobize/circuit/${circuit.uuid}`, { queryParams });

const fetchGroupKobize = (
  group: Models.Groups.Model,
  queryParams?: {
    startDate?: string,
    endDate?: string
  }
) => Fetch<Models.Analytics.Kobize.SumOfKobize>(`/api/bff/analytics/kobize/group/${group.uuid}`, { queryParams });

// const fetchKobizeFromTo = (device: Models.Devices.Device, pointInTime: string, startDate: string, endDate: string) => Fetch<Models.Analytics.Kobize.Model>(`api/analytics/kobize/${device.object_id}`, {});
// if we pass the same
export {
  fetchDeviceTelemetry,
  fetchLastTelemetriesFrom,
  fetchDeviceTelemetriesArray,
  fetchDeviceTelemetriesFromTo,
  fetchCircuitTelemetriesFromTo,
  fetchGroupTelemetriesFromTo,
  fetchEnergyUsedFromTo,
  fetchDeviceKobize,
  fetchCircuitKobize,
  fetchGroupKobize
};
