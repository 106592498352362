import Vue from 'vue';
import Vuetify, {
  VCard, VBtn, VSwitch, VProgressCircular, VIcon, VTextField, VDatePicker, VMenu, VTooltip, VSelect, VCheckbox
} from 'vuetify/lib';
import colors from '@/styles/base/_colors.scss';

Vue.use(Vuetify, {
  // directly imported vuetify components for use in template-based functional SFCs
  components: {
    VCard, VBtn, VSwitch, VProgressCircular, VIcon, VTextField, VDatePicker, VMenu, VTooltip, VSelect, VCheckbox
  },
});

const opts = {
  theme: {
    themes: {
      light: colors
    }
  }
};

export default new Vuetify(opts);
