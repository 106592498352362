



























import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import {
  AppAction,
  AppActionType,
  AppState,
  DrawerTab,
} from '@/store/modules/app/types';
import { UserGetter, UserGetterType } from '@/store/modules/admin/types';
import { TranslateResult } from 'vue-i18n';

const app = namespace('app');
const admin = namespace('admin');

interface permissions {
  [key: string]: any;
}

interface drawerTab {
  name: string,
  value: number,
  icon: string,
}

interface temporaryArray {
  name: TranslateResult,
  value: number,
  icon: string,
}

interface itab {
  name: TranslateResult | null,
  value: number | null,
  icon: string | null,
  permissionType: string | Array<string> | null
}

@Component({
  name: 'TheNavDrawerLinks'
})
/**
 * @vuese
 * @group The NavDrawer
 * Main navigation drawer links (tabs)
 */
export default class TheNavDrawerLinks extends Vue {
  @app.State
  selectedTab!: AppState['selectedTab'];

  @app.State
  mapSelectionEnabled!: AppState['mapSelectionEnabled'];

  @app.Action(AppAction.SetSelectedTab)
  setSelectedTab!: AppActionType['SET_SELECTED_TAB'];

  @admin.Getter(UserGetter.GetPermissions)
  GetPermissions!: UserGetterType['GET_PERMISSIONS'];

  get navItems(): itab[] {
    return [
      {
        name: this.$t('drawerLinks[0]'),
        value: DrawerTab.Users,
        icon: 'mdi-account-multiple',
        permissionType: 'users'
      },
      { 
        name: this.$t('drawerLinks[1]'), 
        value: DrawerTab.Devices, 
        icon: 'mdi-devices',
        permissionType: 'devices'
      },
      { 
        name: this.$t('drawerLinks[2]'), 
        value: DrawerTab.Reports, 
        icon: 'mdi-alert',
        permissionType: 'alerts'
      },
      { 
        name: this.$t('drawerLinks[3]'), 
        value: DrawerTab.Creator, 
        icon: 'mdi-chart-box-plus-outline',
        permissionType: 'devices'
      },
      { 
        name: this.$t('drawerLinks[4]'), 
        value: DrawerTab.Planning, 
        icon: 'mdi-clock-time-four-outline',
        permissionType: 'scenarios'
      },
      { 
        name: this.$t('drawerLinks[5]'), 
        value: DrawerTab.Optimization, 
        icon: 'mdi-finance',
        permissionType: 'optimization'
      },
      { 
        name: this.$t('drawerLinks[6]'), 
        value: DrawerTab.Analytics, 
        icon: 'mdi-poll',
        permissionType: 'analytics'
      },
      // FEATURE NOT READY YET
      // UNCOMMENT THIS BLOCK WHEN THE HISTORICALS (AKA ARCHIVE, AKA HISTORY) CARD IS PREPARED
      // THEN THE NAV ELEMENT WILL BE DISPLAYED IF THE USER IS PERMITTED TO SEE IT
      // { 
      //   name: this.$t('drawerLinks[7]'), 
      //   value: DrawerTab.Archive, 
      //   icon: 'mdi-clock',
      //   permissionType: 'historicals'
      // },
      { 
        name: this.$t('drawerLinks[8]'), 
        value: DrawerTab.Submissions, 
        icon: 'mdi-clipboard-text-multiple',
        permissionType: 'submissions'
      },
    ];
  }

  get filteredNavItems(): itab[] {
    if (!this.GetPermissions.length) {
      return [];
    }
    const getPermissions: any = this.GetPermissions;
    const filteredItems = this.navItems.filter((element) => {
      if (Array.isArray(element.permissionType)) {
        let result = false;
        element.permissionType.forEach((pType) => {
          if (result) return;
          const permission = getPermissions.find((p) => p.type === pType);
          if (permission?.read) result = true;
          else result = false;
        });
        return result;
      }
      const permission = getPermissions.find((p) => p.type === element.permissionType);
      if (permission?.read) return true;
      return false;
    });
    return filteredItems;
  }

  selectTab(tab: DrawerTab) {
    // temporary disabled optimization tab (feature not prepared yet)
    // if (tab === DrawerTab.Optimization) return;
    // temporary disabled archive tab (feature not prepared yet)
    if (tab === DrawerTab.Archive) return;

    // disable tab selection when the user is drawing circuits on the map
    if (this.mapSelectionEnabled) return;

    this.setSelectedTab(tab);
  }

  selectTabDisableProfile(item) {
    this.$store.dispatch('turnOffProfile');
    this.selectTab(item.value);
  }
}
