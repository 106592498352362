import Fetch from '@/utils/fetch';
import { Models } from '@mtap-smartcity/api';

const fetchScenarios = (unixTime) => Fetch<Models.Scenarios.Model[]>(`/api/scenarios?pointInTime=${unixTime}`, {});

const fetchScenario = (uuid: string, unixTime?: number | null) => Fetch<Models.Scenarios.Model[]>(`/api/scenarios/${uuid}?pointInTime=${unixTime}`, {});

const fetchSunriseSunsetRanges = (
  {
    lat, lng, from, to
  }: { lat: number, lng: number, from: string, to: string}
): Promise<any> => Fetch(`/api/scenarios/sunrise-sunset-range?latitude=${lat}&longitude=${lng}&fromDate=${from}&toDate=${to}`, {});

const createScenario = (scenario: Models.Scenarios.Model) => {
  const newScenario = { ...scenario };
  delete newScenario.id; // removing placeholder's id

  return Fetch<Models.Scenarios.Model>('/api/scenarios', {
    method: 'POST',
    body: newScenario
  });
};

const updateScenario = (scenario: Models.Scenarios.Model) => {
  const update = { ...scenario };
  delete update.id; // removing placeholder's id
  return Fetch<Models.Scenarios.Model>(`/api/scenarios/${scenario.uuid}`, {
    method: 'PUT',
    body: update
  });
};

const deleteScenario = (uuid: string) => Fetch(`/api/scenarios/${uuid}`, {
  method: 'DELETE'
});

const setScenarioAsDefault = (uuid: string) => Fetch(`/api/scenarios/${uuid}/default`, {
  method: 'PUT'
});

// const fetchScenarioElements = (unixTime: number, id: number, idField: 'id' | 'scenario_id' | 'graph_id') => Fetch<Models.ScenarioElements.Model[]>(`/api/scenario-elements?id=${id}&idField=${idField}&pointInTime=${unixTime}`, {});

const fetchScenarioElements = (unixTime: number | null) => Fetch<Models.ScenarioElements.Model[]>(`/api/scenario-elements?pointInTime=${unixTime}`, {});

const fetchScenarioElement = (unixTime: number | null, uuid: string) => Fetch<Models.ScenarioElements.Model>(`/api/scenario-elements/${uuid}?pointInTime=${unixTime}`, {});

const fetchScenarioElementsByScenario = (unixTime: number | null, scenarioId: string) => Fetch<Models.ScenarioElements.Model[]>(
  `/api/scenario-elements/scenarios/${scenarioId}?pointInTime=${unixTime}`,
  {}
);

const fetchScenarioElementsByGraph = (unixTime: number, graphId: string) => Fetch<Models.ScenarioElements.Model[]>(`/api/scenario-elements/graphs/${graphId}?pointInTime=${unixTime}`, {});

const createScenarioElement = (scenarioElement: Models.ScenarioElements.Model) => Fetch<Models.ScenarioElements.Model>('/api/scenario-elements', {
  method: 'POST',
  body: scenarioElement
});

const deleteScenarioElement = (uuid: string) => Fetch(`/api/scenario-elements/${uuid}`, {
  method: 'DELETE',
});

const updateScenarioElement = (scenarioElement: Models.ScenarioElements.Model) => {
  const update = { ...scenarioElement };
  delete update.id; // removing placeholder's id
  return Fetch<Models.ScenarioElements.Model>(`/api/scenario-elements/${scenarioElement.uuid}`, {
    method: 'PUT',
    body: update
  });
};

const updateScenarioElementsPriorities = (scenarioElementsPriorities: Array<Models.ScenarioElements.Priority>) => Fetch<Models.ScenarioElements.Priority>('/api/scenario-elements/priority', {
  method: 'PUT',
  body: scenarioElementsPriorities
});

export {
  fetchScenarios,
  fetchScenario,
  createScenario,
  updateScenario,
  deleteScenario,
  setScenarioAsDefault,
  fetchScenarioElements,
  fetchScenarioElement,
  fetchScenarioElementsByScenario,
  fetchScenarioElementsByGraph,
  createScenarioElement,
  deleteScenarioElement,
  updateScenarioElement,
  updateScenarioElementsPriorities,
  fetchSunriseSunsetRanges
};
