interface HttpResponse<T> extends Response {
  parsedBody?: T;
}

interface FetchConfig {
  body?: object,
  queryParams?: object,
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE'
}

const urlString = (basePath: string, queryParams?: object) => {
  if (!queryParams) return basePath;

  const paramsArr: string[] = [];
  Object.entries(queryParams).forEach(([key, val]) => {
    if (val != null) {
      paramsArr.push(`${key}=${val}`);
    }
  });
  const queryStr = paramsArr.join('&');
  const path = queryStr ? basePath.concat('?', queryStr) : basePath;
  return path;
};

export default async function<T> (endpoint: string, config: FetchConfig): Promise<T> {
  const token = localStorage.getItem('token');
  const timeout = 60000;
  const headers = {
    Authorization: `Bearer ${token}`,
    'Content-type': 'application/json',
  };
  const configObj = { ...config, body: JSON.stringify(config.body) };
  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);

  const endpointWithQueryParams = urlString(endpoint, config.queryParams);

  const response: HttpResponse<T> = await window.fetch(endpointWithQueryParams, {
    headers,
    ...configObj,
    signal: controller.signal
  });

  clearTimeout(id);

  let data;
  try {
    data = await response.json();
  } catch (e) {
    data = null;
  }
  if (response.ok) {
    return Promise.resolve(data);
  }
  return Promise.reject(data);
}
