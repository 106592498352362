















import Vue from 'vue';

/**
 * @group Base Components
 * Base rounded button
 */
export default Vue.extend({
  name: 'BaseButton',
  functional: true,
  props: {
    // Disable the button
    disabled: {
      type: Boolean,
      default: false
    },
    // Set color of the button
    color: {
      type: String,
      default: 'primary'
    },
  }
});
