import { Module } from 'vuex';
import { GraphsState } from './types';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';
import { RootState } from '@/store/types';

const state: GraphsState = {
  graphs: [],
};

const namespaced = true;

export const graphs: Module<GraphsState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
