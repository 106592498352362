let handleOutsideClick;

export default {
  bind(el, binding, vnode) {
    handleOutsideClick = (e) => {
      e.stopPropagation();
      // disable click outside behavior for v-select menu windows
      if (e.target.classList.contains('v-list-item__title') || e.target.classList.contains('v-list-item__content')) return;
      const { handler, exclude, arg } = binding.value;
      let clickedOnExcludedEl = false;
      exclude.forEach((refName) => {
        if (!clickedOnExcludedEl) {
          const excludedEl = vnode.context.$refs[refName].$el || vnode.context.$refs[refName];
          clickedOnExcludedEl = excludedEl.contains(e.target);
        }
      });
      // check if we haven't clicked either on an element inside of the bound element
      // or on any of the excluded elements
      if (!el.contains(e.target) && !clickedOnExcludedEl) {
        vnode.context[handler](arg);
      }
    };
    document.addEventListener('click', handleOutsideClick);
    document.addEventListener('touchstart', handleOutsideClick);
  },
  unbind() {
    document.removeEventListener('click', handleOutsideClick);
    document.removeEventListener('touchstart', handleOutsideClick);
  }
};
