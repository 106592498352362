

















import { Vue, Component } from 'vue-property-decorator';

type Locale = 'pl' | 'en'

@Component({
  name: 'LanguageSwitch'
})
/**
 * @vuese
 * @group The NavDrawer
 * Language switch
 */
export default class LanguageSwitch extends Vue {
  langs = [
    { text: 'Polski', value: 'pl' },
    { text: 'English', value: 'en' },
  ]

  switchLang(locale: Locale) {
    if (this.$i18n.locale !== locale) {
      this.$i18n.locale = locale;
      const to = this.$router.resolve({ params: { locale } });
      this.$router.push(to.location);
    }
  }
}
