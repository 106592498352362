import { Module } from 'vuex';
import { SubmissionsState } from './types';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';
import { RootState } from '@/store/types';

const state: SubmissionsState = {
  submissions: [
    {
      id: 1,
      text: 'First task to do',
      address: 'Ul. Juliusza Słowackiego',
      priority: 1,
      status: 'new',
    },
    {
      id: 2,
      text: 'Second task to do',
      address: 'Ul. Adama Mickiewicza',
      priority: 3,
      status: 'todo',
    },
    {
      id: 3,
      text: 'Third task to do',
      address: 'Ul. Adama Mickiewicza',
      priority: 1,
      status: 'done',
    },
    {
      id: 4,
      text: 'Fourth task to do',
      address: 'Ul. Juliusza Słowackiego',
      priority: 2,
      status: 'todo',
    },
    {
      id: 5,
      text: 'Fifth task to do',
      address: 'Ul. Adama Mickiewicza',
      priority: 2,
      status: 'todo',
    },
    {
      id: 6,
      text: 'Sixth task to do',
      address: 'Ul. Adama Mickiewicza',
      priority: 1,
      status: 'new',
    },
  ],
};

const namespaced = true;

export const submissions: Module<SubmissionsState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
