<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" max-width="320" persistent>
      <v-card>
        <v-card-title class="text-h5 grey--text text--darken-2">
          {{ $tc('messages.willBeDeleted', isPlFeminine ? 2 : 1, { element: $tc(`main.${element}`, 1)}) | capitalize }}
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="closeModal"
          >
            {{ $t('actions.return') }}
          </v-btn>
          <v-btn
            color="error"
            text
            @click="deleteModal"
            class="btn--red-bg"
          >
            {{ $t('actions.delete') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>

import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class ModalAction extends Vue { 
  @Prop({ type: Boolean, default: false }) dialog

  @Prop({ type: String, default: 'Element' }) element

  @Prop({ type: Boolean, default: false }) isPlFeminine

  deleteModal() {
    this.$emit('deleteModal');
  }

  closeModal() {
    this.$emit('closeModal');
  }
}
</script>

<style scoped>
.btn--red-bg {
  background-color: rgba(255, 82, 82, 0.1);
}
</style>
