<template functional>
  <v-text-field
    class="c-base-text-field"
    dense
    :disabled="props.disabled"
    hide-details
    :spellcheck="false"
    :placeholder="props.placeholder"
    color="info"
    :value="props.value"
    v-on="listeners"
    :maxlength="props.maxlength"
  >
  </v-text-field>
</template>

<script>
import Vue from 'vue';
/**
 * @group Base Components
 * Base text field
 */
export default Vue.extend({
  name: 'BaseTextField',
  functional: true,
  props: {
    // Disable input
    disabled: {
      type: Boolean,
      default: false
    },
    // Empty field placeholder text
    placeholder: {
      type: String,
      default: 'Nazwa'
    },
    // Text field content
    value: {
      type: [String, Number],
    },
    maxlength: {
      type: [Number],
      default: undefined,
    },
  }
});
</script>

<style scoped lang="scss">
@import '@/styles/main.scss';

.c-base-text-field {
  margin: 0;
  @include setFont(400, color-shade($colors, 'secondary', 'dark'), $xs);
  // TODO replace vuetify's text-field with custom one?
  // the following style adjustments can break on subsequent vuetify updates, but this component's
  // API doesn't seem to provide a way of removing the bottom border and having the overflowing text truncated
  ::v-deep .v-input__slot:before {
    border-style: none;
  }
  ::v-deep .v-input__slot:after {
    border-style: none;
  }
  ::v-deep input {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
