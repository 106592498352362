<script>
import Vue from 'vue';

/**
 * @vuese
 * @group Base Components
 * Headless component for handling on/off logic
 */
export default Vue.extend({
  name: 'StateToggle',
  render() {
    return this.$scopedSlots.default({
      active: this.active,
      toggle: this.toggle
    })[0];
  },
  data() {
    return {
      active: false
    };
  },
  methods: {
    toggle() {
      this.active = !this.active;
      this.$emit('change', this.active);
    }
  }
});
</script>
