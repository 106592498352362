

















import Vue, { PropType } from 'vue';
/**
 * @group Base Components
 * Base tooltip
 */
export default Vue.extend({
  name: 'BaseTooltip',
  functional: true,
  props: {
    // Tooltip main text - first line
    content: {
      type: String,
      required: true
    },
    // Tooltip additional text - second line
    extra: {
      type: String,
    },
    // Tooltip position relative to activator element
    position: {
      // 'top' \| 'right' \| 'left' \| 'bottom'
      type: String as PropType<'top' | 'right' | 'left' | 'bottom'>,
      default: 'right'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    openDelay: {
      type: Number,
      default: 300
    },
  }
});
