var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{class:{
    'c-floating-logo': true,
    'c-floating-logo--expanded': _vm.isExpanded
  }},[_c('img',{staticClass:"mx-6 my-3 c-floating-logo__image",attrs:{"src":_vm.logoSrc}})]),((this.$store.getters.toggleProfile) && _vm.selectedTab === null)?_c('profile-card',{class:{
    'c-floating-profile-card': true,
    'c-floating-profile-card--expanded': _vm.isExpanded
  }}):_vm._e(),_c('TheHistoricalParameters',{class:{
    'c-floating-historical-parmeters': true,
    'c-floating-historical-parmeters--expanded': _vm.isExpanded
  }})],1)}
var staticRenderFns = []

export { render, staticRenderFns }