import { GetterTree } from 'vuex';
import { GroupsState, GroupsGetter } from './types';
import { RootState } from '@/store/types';
import { Models } from '@mtap-smartcity/api';

export const getters: GetterTree<GroupsState, RootState> = {
  [GroupsGetter.GetGroup](state): (uuid: string) => Models.Groups.Model | undefined {
    return (uuid: string) => state.groups.find((group) => uuid && group.uuid === uuid);
  },

  [GroupsGetter.GetGroupById](state): (id: number) => Models.Groups.Model | undefined {
    return (id: number) => state.groups.find((group) => id && group.id === id);
  },
};
