export type CompetenceType = 'circuits' | 'groups' | 'scenarios' | 'devices' | 'users' | 'alerts' | 'historicals' | 'analytics' | 'optimization' | 'submissions';

export interface CompetenceData {
  name: string,
  read: boolean,
  write: boolean,
  delete: boolean
}

const competencesFormObj: Record<CompetenceType, CompetenceData> = {
  users: {
    name: 'managingUsers',
    read: false,
    write: false,
    delete: false
  },
  devices: {
    name: 'managingDevices',
    read: false,
    write: false,
    delete: false
  },
  circuits: {
    name: 'managingCircuits',
    read: false,
    write: false,
    delete: false
  },
  groups: {
    name: 'managingGroups',
    read: false,
    write: false,
    delete: false
  },
  alerts: {
    name: 'managingAlerts',
    read: false,
    write: false,
    delete: false
  },
  scenarios: {
    name: 'managingScenarios',
    read: false,
    write: false,
    delete: false
  },
  optimization: {
    name: 'managingOptimization',
    read: false,
    write: false,
    delete: false
  },
  analytics: {
    name: 'managingAnalytics',
    read: false,
    write: false,
    delete: false
  },
  historicals: {
    name: 'managingHistoricals',
    read: false,
    write: false,
    delete: false
  },
  submissions: {
    name: 'managingSubmissions',
    read: false,
    write: false,
    delete: false
  },
};

export default competencesFormObj;
