







































import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import {
  AppAction, AppActionType, AppState, DrawerTab
} from '@/store/modules/app/types';
import { DevicesAction, DevicesActionType, DevicesState } from '@/store/modules/devices/types';

const devices = namespace('devices');
const app = namespace('app');

@Component
/**
 * @group The NavDrawer
 * Upper left bar with actions related to drawing circuits on the map
 */
export default class MapActionsBar extends Vue {
  @app.State
  showCircuitActionsCard!: AppState['showCircuitActionsCard'];

  @devices.State
  insidePolygonMarkers!: DevicesState['insidePolygonMarkers'];

  @app.Action(AppAction.SetMapSelectionEnabled)
  setMapSelectionEnabled!: AppActionType['SET_MAP_SELECTION_ENABLED'];

  @app.Action(AppAction.SetSelectedTab)
  setSelectedTab!: AppActionType['SET_SELECTED_TAB'];

  @app.Action(AppAction.ToggleCircuitActionsCard)
  toggleCircuitActionsCard!: AppActionType['TOGGLE_CIRCUIT_ACTIONS_CARD'];

  @devices.Action(DevicesAction.SetInsidePolygonMarkers)
  setInsidePolygonMarkers!: DevicesActionType['SET_INSIDE_POLYGON_MARKERS'];

  // enable creating a new circuit from the selected polygon only if there is at least one lamp inside of it
  get circuitCreationEnabled() {
    return !!this.insidePolygonMarkers.length;
  }

  get tooltipContent() {
    if (this.showCircuitActionsCard) {
      return this.$t('tooltips.mapActions[1]');
    }
    if (this.circuitCreationEnabled) {
      return this.$t('tooltips.mapActions[3]');
    }
    return this.$t('tooltips.mapActions[2]');
  }

  discard() {
    this.setInsidePolygonMarkers([]);
    this.setMapSelectionEnabled(false);
    this.toggleCircuitActionsCard(false);
    this.setSelectedTab(DrawerTab.Creator);
  }
}
