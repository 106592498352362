import { GetterTree } from 'vuex';
import { ScenariosState, ScenariosGetter } from './types';
import { RootState } from '@/store/types';
import { Models } from '@mtap-smartcity/api';

export const getters: GetterTree<ScenariosState, RootState> = {
  [ScenariosGetter.GetScenario](state): (uuid: string) => Models.Scenarios.Model | undefined {
    return (uuid: string) => state.scenarios.find((s) => s.uuid === uuid);
  },

  [ScenariosGetter.GetScenarioById](state): (id: number) => Models.Scenarios.Model | undefined {
    return (id: number) => state.scenarios.find((s) => s.id === id);
  },

  [ScenariosGetter.GetScenarioElement](state): (uuid: string) => (Models.ScenarioElements.Model | undefined) {
    return (uuid: string) => state.scenarioElements.find((s) => s.uuid === uuid);
  },

  [ScenariosGetter.GetScenarioElementById](state): (id: number) => (Models.ScenarioElements.Model | undefined) {
    return (id: number) => state.scenarioElements.find((s) => s.id === id);
  }
};
