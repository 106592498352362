import { Models } from '@mtap-smartcity/api';

interface TelemetryState {
  telemetries: Array<Models.Telemetries.Telemetry>,
  aggregatedTelemetry: Models.Telemetries.SumOfTelemetry | null,
  lastTelemetries: Array<Models.Telemetries.Telemetry>,
  selectedReading: Models.Telemetries.Telemetry | null,
  energyConsumption: number;
  co2Emission: number,
  energyUsage: number,
}

enum TelemetryGetter {
  GetLastTelemetry = 'GET_LAST_TELEMETRY'
}

type TelemetryGetterType = {
  [TelemetryGetter.GetLastTelemetry]: (
    objectId: string,
    deviceType: Models.Constants.DeviceType
  ) => Models.Telemetries.Telemetry | null
}

enum TelemetryAction {
  FetchDeviceTelemetry = 'FETCH_DEVICE_TELEMETRY',
  FetchLastTelemetriesFrom = 'FETCH_LAST_TELEMETRIES_FROM',
  FetchDeviceTelemetriesArray = 'FETCH_DEVICE_TELEMETRIES_ARRAY',
  FetchDeviceTelemetriesFromTo = 'FETCH_DEVICE_TELEMETRIES_FROM_TO',
  FetchCircuitTelemetriesFromTo = 'FETCH_CIRCUIT_TELEMETRIES_FROM_TO',
  FetchGroupTelemetriesFromTo = 'FETCH_GROUP_TELEMETRIES_FROM_TO',
  FetchEnergyUsedFromTo = 'FETCH_ENERGY_USED_FROM_TO',
  FetchDeviceKobize = 'FETCH_DEVICE_KOBIZE',
  FetchCircuitKobize = 'FETCH_CIRCUIT_KOBIZE',
  FetchGroupKobize = 'FETCH_GROUP_KOBIZE',
  SetSelectedReading = 'SET_SELECTED_READING',
}

type TelemetryActionType = {
  [TelemetryAction.FetchDeviceTelemetry]: (
    { objectId }: { objectId: string }
  ) => Promise<void>,
  [TelemetryAction.FetchLastTelemetriesFrom]: (
    queryParams?: { attributes?: string, timestampFrom?: string }
  ) => Promise<void>,
  [TelemetryAction.FetchDeviceTelemetriesArray]: (
    queryParams: {
      limit?: number,
      offset?: number,
      deviceId?: string | null,
      error?: number | number[]
    }
  ) => Promise<void>,
  [TelemetryAction.FetchDeviceTelemetriesFromTo]: (
    queryParams: {
      timestampFrom?: string,
      timestampTo?: string,
      deviceId?: string | null
    }
  ) => Promise<void>,
  [TelemetryAction.FetchCircuitTelemetriesFromTo]: (
    {
      objectId,
      queryParams
    }: {
      objectId: string,
      queryParams: {
        startDate?: string,
        endDate?: string,
        timestamp_delta?: number
      }
    }
  ) => Promise<void>,
  [TelemetryAction.FetchGroupTelemetriesFromTo]: (
    {
      objectId,
      queryParams
    }: {
      objectId: string,
      queryParams: {
        startDate?: string,
        endDate?: string,
        timestamp_delta?: number
      }
    }
  ) => Promise<void>,
  [TelemetryAction.FetchEnergyUsedFromTo]: (
    queryParams: {
      timestampFrom?: string,
      timestampTo?: string,
      deviceId?: string | null
    }
  ) => Promise<void>,
  [TelemetryAction.FetchDeviceKobize]: (
    {
      device,
      queryParams
    }: {
      device: Models.Devices.Device,
      queryParams?: {
        pointInTime?: string,
        startDate?: string,
        endDate?: string
      }
    }
  ) => Promise<void>,
  [TelemetryAction.FetchCircuitKobize]: (
    {
      circuit,
      queryParams
    }: {
      circuit: Models.Circuits.Model,
      queryParams?: {
        startDate?: string,
        endDate?: string
      }
    }
  ) => Promise<void>,
  [TelemetryAction.FetchGroupKobize]: (
    {
      group,
      queryParams
    }: {
      group: Models.Groups.Model,
      queryParams?: {
        startDate?: string,
        endDate?: string
      }
    }
  ) => Promise<void>,
  [TelemetryAction.SetSelectedReading]: (
    { reading }: { reading: Models.Telemetries.Telemetry | null }
  ) => void,
}

export {
  TelemetryState,
  TelemetryGetter,
  TelemetryGetterType,
  TelemetryAction,
  TelemetryActionType
};
