import { ActionTree } from 'vuex';
import { SubmissionsState, SubmissionsAction } from './types';
// import { userActionDispatcher } from '../app/actions';
import { RootState } from '@/store/types';
import { Submission } from '@/types/submissions';

export const actions: ActionTree<SubmissionsState, RootState> = {
  [SubmissionsAction.CreateSubmission]({ state, commit }, submission: Submission): void {
    // userActionDispatcher(dispatch, (this as any).$app.$t('messages.creatingSubmission'), 'pending');
    const newSubmission = {
      id: state.submissions.length + 1,
      text: submission.text,
      address: submission.address,
      priority: submission.priority,
      status: 'new',
    };
    // userActionDispatcher(dispatch, '', 'success');
    return commit(`${SubmissionsAction.CreateSubmission}Handler`, newSubmission);
  },
  [SubmissionsAction.UpdateSubmission]({ commit }, submission: Submission): void {
    // userActionDispatcher(dispatch, (this as any).$app.$t('messages.updatingSubmission'), 'pending');
    const updatedSubmission = {
      id: submission.id,
      text: submission.text,
      address: submission.address,
      priority: submission.priority,
      status: submission.status,
    };
    // userActionDispatcher(dispatch, '', 'success');
    return commit(`${SubmissionsAction.UpdateSubmission}Handler`, updatedSubmission);
  },
  [SubmissionsAction.DeleteSubmission]({ commit }, id: number): void {
    // userActionDispatcher(dispatch, (this as any).$app.$t('messages.deletingSubmission'), 'pending');
    // userActionDispatcher(dispatch, '', 'success');
    return commit(`${SubmissionsAction.DeleteSubmission}Handler`, id);
  },
  [SubmissionsAction.UpdateSelectedSubmissionSTATUS]({ state, commit }, { id, newStatus }: { id: number, newStatus: string }): void {
    // userActionDispatcher(dispatch, (this as any).$app.$t('messages.updatingSubmission'), 'pending');
    const submissionIndex: number = state.submissions.findIndex((submission: Submission) => submission.id === id);
    const submission = { ...state.submissions[submissionIndex] };
    submission.status = newStatus;
    // userActionDispatcher(dispatch, '', 'success');
    return commit(`${SubmissionsAction.UpdateSubmission}Handler`, submission);
  },
};
