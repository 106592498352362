import { MutationTree } from 'vuex';
import { SubmissionsState, SubmissionsAction } from './types';
import { Submission } from '@/types/submissions';

export const mutations: MutationTree<SubmissionsState> = {
  [`${SubmissionsAction.CreateSubmission}Handler`](state, newSubmission: Submission): void {
    state.submissions.unshift(newSubmission);
  },
  [`${SubmissionsAction.UpdateSubmission}Handler`](state, submission: Submission): void {
    const submissionIndex: number = state.submissions.findIndex((sub: Submission) => sub.id === submission.id);
    state.submissions.splice(submissionIndex, 1, submission);
  },
  [`${SubmissionsAction.DeleteSubmission}Handler`](state, id: number): void {
    const submissionIndex: number = state.submissions.findIndex((sub: Submission) => sub.id === id);
    state.submissions.splice(submissionIndex, 1);
  },
};
