
























































































import {
  Component, Ref, Vue, Watch
} from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import {
  UserGetter, UserGetterType, AdminAction, AdminActionType
} from '@/store/modules/admin/types';
import { AppGetter } from '@/store/modules/app/types';
import { getLoggedUserId } from '@/utils/helpers';
import { Models } from '@mtap-smartcity/api';

const admin = namespace('admin');

@Component({
})
export default class ProfileCard extends Vue {
  @Ref() form!: any

  @Ref() confirmInput!: any

  @Ref() passwordInput!: any

  inputsVisible: boolean = false;

  editableUser: Partial<Models.Bff.Auth.Users.Model> = {};

  password: string = '';

  confirmPassword: string = '';

  showConfirmPassword: boolean = false;

  showPassword: boolean = false;

  passwordMessage: string = '';

  submitFailed: boolean = false;

  valid: boolean = true;

  @admin.Action(AdminAction.FetchUser)
  fetchUser!: AdminActionType['FETCH_USER'];

  @admin.Action(AdminAction.ChangeUserPassword)
  changeUserPassword!: AdminActionType['CHANGE_USER_PASSWORD'];

  @admin.Getter(UserGetter.GetUser)
  getUser!: UserGetterType['GET_USER'];

  toggleInputs() {
    this.inputsVisible = !this.inputsVisible;
    this.clearInputs();
  }

  clearInputs() {
    this.password = '';
    this.confirmPassword = '';
    this.passwordMessage = '';
    this.submitFailed = false;
    this.valid = false;
  }

  passwordSubmit() {
    this.passwordMessage = '';
    if (this.form.validate()) {
      this.changeUserPassword({
        password: this.password,
        id: this.editableUser.id,
      })
        .then(() => {
          this.passwordMessage = 'actions.passwordSuccess';
          setTimeout(() => this.toggleInputs(), 2000);
          this.valid = false;
        })
        .catch(() => {
          this.submitFailed = true;
          this.passwordMessage = 'actions.passwordFailed';
          this.valid = false;
        });
    }
  }

  @Watch('$i18n.locale')
  on$i18nLocaleChange() {
    this.form.validate();
  }

  async created() {
    const token = this.$store.getters[`app/${AppGetter.GetToken}`];
    const userId = getLoggedUserId(token);
    if (!userId) return;
    try {
      await this.fetchUser(userId);
    } catch (error) {
      console.error(error);
    }
  }

  async beforeMount() {
    this.editableUser = this.getUser;
  }
}

