















import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { AppState } from '@/store/modules/app/types';
import TheNavDrawer from '@/components/TheNavDrawer/TheNavDrawer.vue';
import TheMap from '@/components/TheMap/TheMap.vue';
import TheControlCard from '@/components/TheControlCard/TheControlCard.vue';
import {
  AdminState,
  UserGetter, UserGetterType
} from '@/store/modules/admin/types';

const app = namespace('app');

const admin = namespace('admin');

@Component({
  components: {
    TheControlCard,
    TheMap,
    TheNavDrawer,
    TheMainCard: () => import('@/components/TheMainCard/TheMainCard.vue'),
    CircuitActionsCard: () => import('@/components/CircuitActionsCard.vue'),
    MapActionsBar: () => import('@/components/MapActionsBar.vue')
  }
})
export default class HomeView extends Vue {
  showMapActions = false;

  isPermission = false;

  @admin.State
  permissions!: AdminState['permissions'];

  @admin.Getter(UserGetter.GetPermissions)
  GetPermissions!: UserGetterType['GET_PERMISSIONS'];

  @Watch('permissions', { deep: true, immediate: true })
  onPermission() {
    const permissionDevicesWrite = this.GetPermissions.find((item) => item.type === 'devices')?.write;
    const permissionCircuitsWrite = this.GetPermissions.find((item) => item.type === 'circuits')?.write;
    const permissionGroupsWrite = this.GetPermissions.find((item) => item.type === 'groups')?.write;
    this.isPermission = permissionDevicesWrite || permissionCircuitsWrite || permissionGroupsWrite;
  }

  @app.State
  showCircuitActionsCard!: AppState['showCircuitActionsCard']

  @app.State
  selectedTab!: AppState['selectedTab']

  @app.State
  mapSelectionEnabled!: AppState['mapSelectionEnabled']

  @app.State
  triggerHistoricals!: AppState['triggerHistoricals']

  @Watch('mapSelectionEnabled')
  onMapSelectionEnabledChange(enabled: boolean) {
    if (enabled) {
      setTimeout(() => {
        this.showMapActions = enabled;
      }, 400);
    } else {
      this.showMapActions = enabled;
    }
  }
}
