import Vue from 'vue';
import App from './App.vue';
import getRouter from './router';
import store from './store';
import getKeycloak from '@/plugins/keycloak';
import vuetify from './plugins/vuetify';
import getI18n from './plugins/i18n';
import getMaps from './plugins/maps';
import outsideClickDirective from './directives/outsideClickDirective';
import { capitalize } from './utils/string_format';
import GmapCluster from 'vue2-google-maps/dist/components/cluster';

// custom directives registration
Vue.directive('outside-click', outsideClickDirective);

// custom filters registration
Vue.filter('capitalize', capitalize);

// global components registration
const requireComponent = require.context(
  './components/base',
  true,
  /.*\.(vue)$/
);

requireComponent.keys().forEach((filePath) => {
  const componentConfig = requireComponent(filePath);
  const fileName = (filePath.split('/').pop() as string).replace(/\.\w+$/, '');
  Vue.component(fileName, componentConfig.default);
});

Vue.component('GmapCluster', GmapCluster);

// Vue config
Vue.config.productionTip = false;

(async () => {
  await store.dispatch('app/SET_RUNTIME_CONFIG');

  const router = await getRouter();
  const i18n = await getI18n();
  const keycloak = await getKeycloak();
  await getMaps();

  Vue.prototype.$keycloak = keycloak;

  try {
    const auth = await keycloak.keycloak.init({
      onLoad: keycloak.config.onLoad,
      checkLoginIframe: false
    });

    if (!auth) {
      window.location.reload();
    } else {
      const { keycloak: { token, refreshToken } } = keycloak;

      if (token) localStorage.setItem('token', token);
      if (refreshToken) localStorage.setItem('refresh-token', refreshToken);
      const app = new Vue({
        router,
        vuetify,
        store,
        i18n,
        render: (h) => h(App)
      });

      store.$app = app;

      app.$mount('#app');
    }
    store.dispatch('app/SET_AUTHENTICATION_HEADER');
    setInterval(() => {
      keycloak.keycloak.updateToken(70).then((refreshed) => {
        if (refreshed) {
          const refreshedToken = keycloak.keycloak.token;
          if (refreshedToken) localStorage.setItem('token', refreshedToken);
        }
      });
    }, 60000);
  } catch {
    console.error('error');
  }
})();
