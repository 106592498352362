import Fetch from '@/utils/fetch';
import { Models } from '@mtap-smartcity/api';

const fetchCircuits = (unixTime) => Fetch<Models.Circuits.Model[]>(`/api/circuits?pointInTime=${unixTime}`, {});

const createCircuit = (circuit: Models.Circuits.Model) => {
  const newCircuit: any = { ...circuit };
  delete newCircuit.id; // removing placeholder's id

  return Fetch<Models.Circuits.Model>('/api/circuits', {
    method: 'POST',
    body: newCircuit
  });
};

const updateCircuit = (circuit: Models.Circuits.Model) => Fetch<Models.Circuits.Model>(`/api/circuits/${circuit.uuid}`, {
  method: 'PUT',
  body: circuit,
});

const deleteCircuit = (uuid: string) => Fetch(`/api/circuits/${uuid}`, {
  method: 'DELETE'
});

const addCircuitToGroup = (uuid: string, group_id: string | null) => Fetch<Models.Circuits.Model>(`/api/circuits/${uuid}/group`, {
  body: {
    group_id,
  },
  method: 'PUT'
});

const addCircuitToScenario = (circuit: Models.Circuits.Model) => Fetch(`/api/circuits/${circuit.uuid}/scenario`, {
  method: 'PUT',
  body: circuit,
});

const overwriteCircuitSettings = (circuit: Models.Circuits.Model) => Fetch(`/api/circuits/${circuit.uuid}/overwrite`, {
  method: 'PUT',
  body: circuit,
});

export {
  fetchCircuits,
  createCircuit,
  updateCircuit,
  deleteCircuit,
  addCircuitToGroup,
  addCircuitToScenario,
  overwriteCircuitSettings
};
