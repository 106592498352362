import { MutationTree } from 'vuex';
import { GroupsState, GroupsAction } from './types';
import { Models } from '@mtap-smartcity/api';

export const mutations: MutationTree<GroupsState> = {
  [`${GroupsAction.FetchGroups}Handler`](state, groups: Array<Models.Groups.Model>): void {
    state.groups = groups;
  },

  [`${GroupsAction.AddGroupPlaceholder}Handler`](state): void {
    state.groups = [{
      id: undefined,
      name: '',
      version: 0
    }, ...state.groups];
  },

  [`${GroupsAction.RemoveGroupPlaceholder}Handler`](state): void {
    const placeholderGroupIndex = state.groups.findIndex((group) => !group.id);
    if (placeholderGroupIndex !== -1) {
      state.groups.splice(placeholderGroupIndex, 1);
    }
  },

  [`${GroupsAction.CreateGroup}Handler`](state, item: Models.Groups.Model): void {
    state.groups.push(item);
  },

  [`${GroupsAction.UpdateGroup}Handler`](state, newGroupData: Models.Groups.Model): void {
    const groupIndex = state.groups.findIndex((group) => group.uuid === newGroupData.uuid);
    state.groups.splice(groupIndex, 1, newGroupData);
  },

  [`${GroupsAction.DeleteGroup}Handler`](state, uuid: string): void {
    state.groups = state.groups.filter((group) => group.uuid !== uuid);
  },

  [`${GroupsAction.SetSelectedGroupID}Handler`](state, id: number): void {
    state.selectedGroupID = id;
  },

  [`${GroupsAction.SetSelectedGroupUuid}Handler`](state, uuid: string): void {
    state.selectedGroupUuid = uuid;
  }
};
