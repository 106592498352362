import { Module } from 'vuex';
import { AppState } from './types';
import { actions } from './actions';
import { mutations } from './mutations';
import { RootState } from '@/store/types';
import { getters } from './getters';
import { RuntimeConfig } from '@/types/config';

const state: AppState = {
  runtimeConfig: { STARTING_LAT: 53.82267941325178, STARTING_LNG: 22.364011961802785 } as RuntimeConfig,
  userActionStatus: {
    message: '',
    status: 'success'
  },
  timeoutID: null,
  selectedTab: null,
  selectedDevicesTab: 'lamp',
  selectedAnalyticsTab: 'none',
  selectedCreatorTab: 'circuits',
  mapSelectionEnabled: false,
  showCircuitActionsCard: false,
  mapFocusPoint: { lat: 0, lng: 0 },
  triggerHistoricals: false,
  timeHistoricals: 0,
  token: '',
  refreshToken: '',
  tokenExpire: 0,
  refreshTokenExpire: 0,
};

const namespaced = true;

export const app: Module<AppState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
