


















import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
/**
 * @group Base Components
 * Component showing progress circle when in loading state, otherwise showing actionable activator component (slot content)
 */
export default class CircularLoader extends Vue {
  // Add opacity to the progress circle
  @Prop({ type: Boolean, default: false }) readonly disabled!: boolean

  // Progress circle color
  @Prop({ type: String, default: '#ffc200' }) readonly color!: boolean

  // Progress circle stroke-width
  @Prop({ type: Number, default: 10 }) readonly width!: boolean

  // Progress circle width and height
  @Prop({ type: Number, default: 10 }) readonly size!: boolean

  loading = false;

  switchLoader() {
  /**
   * @vuese
   * Used to toggle between progress circle and actionable activator component inside the slot
   */
    this.loading = !this.loading;
  }
}
