import { Module } from 'vuex';
import { GroupsState } from './types';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';
import { RootState } from '@/store/types';

const state: GroupsState = {
  groups: [],
  selectedGroupID: null,
  selectedGroupUuid: null
};

const namespaced = true;

export const groups: Module<GroupsState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
