import { RuntimeConfig } from '@/types/config';
import axios from 'axios';

export const Axios = axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
});

export default async () => Axios.get<RuntimeConfig>('/config/config.json');
