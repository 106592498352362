<template functional>
  <div class="c-async-loading">
    <v-progress-circular
      indeterminate
      color="grey"
    ></v-progress-circular>
  </div>
</template>

<script>
import Vue from 'vue';

/**
 * @vuese
 * @group Base Components
 * Component displayed while loading an async component.
 */
export default Vue.extend({
  name: 'AsyncLoading',
  functional: true
});
</script>

<style lang="scss">
@import '@/styles/main';

.c-async-loading {
  height: 100%;
  width: 100%;
  flex: 1 1 auto;
  background-color: color-shade($colors, 'secondary', 'base');
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
