




















import { Component, Vue, Watch } from 'vue-property-decorator';
import DateTimePicker from './DateTimePicker.vue';
import { namespace } from 'vuex-class';
import { UserGetter, UserGetterType } from '@/store/modules/admin/types';
import {
  AppAction, AppActionType, AppState,
} from '@/store/modules/app/types';

const admin = namespace('admin');
const app = namespace('app');

@Component({
  components: {
    DateTimePicker,
  },
})
/**
 * @group Control Card Group
 * A card for displaing/setting duty of a selected item (device/circuit/group)
 */
export default class HistoricalSection extends Vue {
  isPermission = false;

  historicalDate = (new Date()).toISOString();

  showDatetimePicker = true;

  get historicalDateMilliseconds() {
    return (new Date(this.historicalDate).getTime()) / 1000;
  }

  @app.State
  triggerHistoricals!: AppState['triggerHistoricals']

  @admin.State
  permissions!: Object[];

  @admin.Getter(UserGetter.GetPermissions)
  GetPermissions!: UserGetterType['GET_PERMISSIONS'];

  @app.Action(AppAction.SetTriggerHistoricals)
  setTriggerHistoricals!: AppActionType['SET_TRIGGER_HISTORICALS']

  @app.Action(AppAction.SetTimeHistoricals)
  setTimeHistoricals!: AppActionType['SET_TIME_HISTORICALS']

  // get permissionCheck() {
  //   const getPermissions = this.GetPermissions.find((item) => item.type === 'historicals');
  //   return getPermissions.read;
  // }

  @Watch('permissions', { deep: true })
  onPermission() {
    const getPermissions = this.GetPermissions.find(
      (item) => item.type === 'historicals'
    );
    this.isPermission = getPermissions.read;
  }

  @Watch('$i18n.locale')
  on$i18nLocaleChange() {
    this.forceRerender();
  }

  triggerOnHistoricals() {
    this.setTriggerHistoricals(true);
    localStorage.setItem('HistoricalTrigger', 'true');
    localStorage.setItem('HistoricalDate', this.historicalDate);
    this.setTimeHistoricals(this.historicalDateMilliseconds);
  }

  triggerOffHistoricals() {
    this.setTriggerHistoricals(false);
    localStorage.setItem('HistoricalTrigger', 'false');
    localStorage.setItem('HistoricalDate', '');
    this.historicalDate = '';
    this.setTimeHistoricals(Math.floor(new Date().getTime() / 1000));
  }

  mounted() {
    const historicalTrigger = localStorage.getItem('HistoricalTrigger');
    if (!historicalTrigger) {
      this.setTriggerHistoricals(false);
      this.historicalDate = '';
    } else {
      this.setTriggerHistoricals(JSON.parse(historicalTrigger));
      this.historicalDate = localStorage.getItem('HistoricalDate') as string;
    }
  }

  forceRerender() {
    this.showDatetimePicker = false;
    this.$nextTick().then(() => {
      this.showDatetimePicker = true;
    });
  }
}
