

















































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { Option } from '@/components/base/BaseSelect.vue';
import { TranslateResult } from 'vue-i18n';

export interface FilterOption {
  name: string | TranslateResult,
  selectOptions: Array<Option>
}

export interface FilterParam {
  name: string | TranslateResult,
  value: number | string | null
}

@Component
/**
 * @group Base Components
 * Wrapper component for displaying filtered list of elements and adding new ones.<br/>
 * Features:<br/>
 * - search field at the top for inputing search phrase, which is bound to the scoped slot
 * and can be used for filtering<br/>
 * - ( + ) button at the bottom for adding new elements
 */
export default class SearchAndAddBox extends Vue {
  // Add ( + ) button at the bottom
  @Prop({ default: true, type: Boolean }) readonly addOption!: boolean

  // Search field placeholder
  @Prop({ required: true, type: String }) readonly searchPlaceholder!: string

  // Disable adding new items
  @Prop({ default: false, type: Boolean }) readonly btnDisabled!: boolean

  @Prop({ default: true, type: Boolean }) readonly btnRemove!: boolean

  @Prop({ default: true, type: Boolean }) readonly permissionCheckEdit!: boolean

  @Prop({ default: false, type: Boolean }) readonly filter!: boolean

  @Prop({ type: Array, default: () => [], required: false }) readonly filterOptions!: Array<FilterOption>

  searchPhrase = ''

  scrolled = false

  filterOptionsVisible: boolean = false;

  computedFilterParams: Array<FilterParam> =
    this.filterOptions.map((fO: FilterOption) => ({ name: fO.name, value: fO.selectOptions[0].value }));

  resetFilterOptions() {
    // eslint-disable-next-line no-return-assign, no-param-reassign
    this.computedFilterParams.forEach((fP: FilterParam) => fP.value = '');
  }

  handleScroll() {
    this.scrolled = true;
  }

  addItem() {
    this.searchPhrase = '';
    this.$emit('add-item');
  }

  showFilterOptions() {
    if (this.filterOptions.length) this.filterOptionsVisible = !this.filterOptionsVisible;
  }

  scrollToSelectedItem(item: Partial<HTMLElement>) {
    const listTopPaddingValue = Number(window.getComputedStyle(item.parentElement!).paddingTop.slice(0, -2)) ?? 0;
    item.parentElement!.scroll({
      top: item.offsetTop! - listTopPaddingValue,
      behavior: 'smooth'
    });
  }

  mounted() {
    const listItem: Partial<HTMLElement> | null = document.querySelector('.c-editable-list-item--selected');
    if (listItem) {
      this.$nextTick(() => this.scrollToSelectedItem(listItem));
    }
  }
}
