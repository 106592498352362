
















import Vue, { PropType } from 'vue';

export interface ITab {
  name: string,
  value: number | string
}

/**
 * @group Base Components
 * Horizontal tabs switcher
 */
export default Vue.extend({
  name: 'CardTabs',
  functional: true,
  props: {
    // Array of available tabs
    tabs: {
      // Array<name: string, value: number \| string>
      type: Array as PropType<Array<ITab>>,
      required: true
    },
    // Selected tab's value
    selectedTab: {
      type: [Number, String],
      required: true
    }
  }
});
