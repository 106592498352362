import { Models } from '@mtap-smartcity/api';

const isLamp = (device: Models.Devices.Device): device is Models.Devices.Lamp => device.device_type === 'lamp';

const isIotLamp = (device: Models.Devices.Device): device is Models.Devices.LampIot => isLamp(device) && device.controller_type === 'iothub';

const isVELamp = (device: Models.Devices.Device): device is Models.Devices.LampVe => device.device_type === 'lamp' && device.controller_type === 've';

const isGateway = (device: Models.Devices.Device): device is Models.Devices.Gateway => device.device_type === 'gateway';

const isBridge = (device: Models.Devices.Device): device is Models.Devices.Bridge => device.device_type === 'bridge';

const isSensor = (device: Models.Devices.Device): device is Models.Devices.Sensor => device.device_type === 'sensor';

// const isControlCabinet = (device: Models.Devices.Device): device is Models.Devices.ControlCabinet => device.device_type === 'control-cabinet';

const isDali1Telemetry = (
  telemetry: Models.Telemetries.Model
): telemetry is Models.Telemetries.LampMtap1V1Telemetry | Models.Telemetries.LampVe1V1Telemetry => (
  telemetry as Models.Telemetries.LampMtap1V1Telemetry | Models.Telemetries.LampVe1V1Telemetry
).telemetry.type && (
  telemetry as Models.Telemetries.LampMtap1V1Telemetry | Models.Telemetries.LampVe1V1Telemetry
).telemetry.type === 'Dali 1.0';

const isDali2Telemetry = (
  telemetry: Models.Telemetries.Model
): telemetry is Models.Telemetries.LampMtap2V1Telemetry | Models.Telemetries.LampVe2V1Telemetry => (
  telemetry as Models.Telemetries.LampMtap2V1Telemetry | Models.Telemetries.LampVe2V1Telemetry
).telemetry.type && (
  telemetry as Models.Telemetries.LampMtap2V1Telemetry | Models.Telemetries.LampVe2V1Telemetry
).telemetry.type === 'Dali 2.0';

const isSensorTelemetry = (telemetry: Models.Telemetries.Model): telemetry is Models.Telemetries.SensorMtap1V1Telemetry => telemetry.device_type === 'sensor';

const isControlCabinetTelemetry = (
  telemetry: Models.Telemetries.Model
): telemetry is (
  Models.Telemetries.GatewayControlCabinet1V1Telemetry | Models.Telemetries.LampControlCabinet1V1Telemetry
) => (
  telemetry.controller_type === 'LAMP_CONTROL_CABINET_1_V1'
  || telemetry.controller_type === 'GATEWAY_CONTROL_CABINET_1_V1'
);

const hasCoordinates = (deviceInParams: any): deviceInParams is { latitude: number; longitude: number } => deviceInParams.longitude && deviceInParams.latitude;

const hasMaxPower = (deviceInParams: any): deviceInParams is { maxPower: number; } => deviceInParams.maxPower;

export {
  isLamp,
  isIotLamp,
  isVELamp,
  isGateway,
  isBridge,
  isSensor,
  isDali1Telemetry,
  isDali2Telemetry,
  isSensorTelemetry,
  isControlCabinetTelemetry,
  hasCoordinates,
  hasMaxPower,
};
