import { MutationTree } from 'vuex';
import { AdminState, AdminAction } from './types';
import { KeycloakRole } from '@/types/users';
import { Models } from '@mtap-smartcity/api';

export const mutations: MutationTree<AdminState> = {
  // [`${AdminAction.FetchUsers}Handler`](state, users: Models.Bff.Auth.Users.Model[]) {
  [`${AdminAction.FetchUsers}Handler`](state, users: Models.Bff.Auth.Users.Model[]) {
    state.users = users;
  },
  // [`${AdminAction.FetchUser}Handler`](state, user: Models.Bff.Auth.Users.Model) {
  [`${AdminAction.FetchUser}Handler`](state, user: Models.Bff.Auth.Users.Model) {
    const userInUsers = state.users.findIndex((u) => u.id === user.id);
    state.users[userInUsers] = user;
    state.user = user;
  },
  // [`${AdminAction.AddUserPlaceholder}Handler`](state, placeholderUser: Models.Bff.Auth.Users.Model) {
  [`${AdminAction.AddUserPlaceholder}Handler`](state, placeholderUser: Models.Bff.Auth.Users.Model) {
    state.users = [placeholderUser, ...state.users];
  },
  [`${AdminAction.RemoveUserPlaceholder}Handler`](state) {
    const placeholderUserIndex = state.users.findIndex((u) => !u.id);
    if (placeholderUserIndex !== -1) {
      state.users.splice(placeholderUserIndex, 1);
    }
  },
  [`${AdminAction.CreateUser}Handler`](state, user: Models.Bff.Auth.Users.Model) {
    state.users.push(user);
  },
  [`${AdminAction.UpdateUser}Handler`](state, updatedUser: Models.Bff.Auth.Users.Model) {
    const userIndex = state.users.findIndex((u) => u.id === updatedUser.id);
    state.users.splice(userIndex, 1, updatedUser);
  },
  [`${AdminAction.DeleteUser}Handler`](state, userId: string) {
    const userIndex = state.users.findIndex((u) => u.id === userId);
    state.users.splice(userIndex, 1);
    state.deleteUserFlag = !state.deleteUserFlag;
  },
  [`${AdminAction.ChangeUserPassword}Handler`](state, user: Partial<Models.Bff.Auth.Users.Model>) {
    const userIndex = state.users.findIndex((u) => u.id === user);
    state.users[userIndex] = {
      ...state.users[userIndex],
      password: user.password,
    };
  },
  [`${AdminAction.SetSelectedUser}Handler`](state, payload: Models.Bff.Auth.Users.Model | null) {
    state.selectedUser = payload;
  },
  [`${AdminAction.AddUserToGroup}Handler`](state, {
    id, userId, name, userEnabled
  }: { id: string, name: string; userId: string, userEnabled: boolean }) {
    const user = state.users.find((u) => u.id === userId);
    if (!user) return;
    if (userEnabled) {
      user.groupId!.push({ id, name });
    } else {
      const idx = user.groupId!.findIndex((g) => g.id === id);
      user.groupId!.splice(idx, 1);
    }
  },
  [`${AdminAction.FetchGroups}Handler`](state, groups: Models.Auth.Groups.GroupsModel[]) {
    state.groups = groups;
  },
  [`${AdminAction.AddGroupPlaceholder}Handler`](state, placeholderGroup: Models.Auth.Groups.GroupsModel) {
    state.groups = [placeholderGroup, ...state.groups];
  },
  [`${AdminAction.RemoveGroupPlaceholder}Handler`](state) {
    const placeholderGroupIndex = state.groups.findIndex((g) => !g.id);
    if (placeholderGroupIndex !== -1) {
      state.groups.splice(placeholderGroupIndex, 1);
    }
  },
  [`${AdminAction.CreateGroup}Handler`](state, group: Models.Auth.Groups.GroupsModel) {
    const placeholdeGroupIndex = state.groups.findIndex((g) => !g.id);
    state.groups.splice(placeholdeGroupIndex, 1, group);
  },
  [`${AdminAction.UpdateGroup}Handler`](state, updatedGroup: Models.Auth.Groups.GroupsModel) {
    const groupIndex = state.groups.findIndex((u) => u.id === updatedGroup.id);
    state.groups.splice(groupIndex, 1, updatedGroup);
  },
  [`${AdminAction.DeleteGroup}Handler`](state, groupId: string) {
    const groupIndex = state.groups.findIndex((g) => g.id === groupId);
    state.groups.splice(groupIndex, 1);
  },
  [`${AdminAction.SetSelectedGroup}Handler`](state, payload: Models.Auth.Groups.GroupsModel | null) {
    state.selectedGroup = payload;
  },
  [`${AdminAction.Permissions}Handler`](state, payload: []) {
    state.permissions = payload;
  },
  [`${AdminAction.FetchAvailableRoles}Handler`](state, roles: KeycloakRole[]) {
    state.availableRoles = roles;
  },
  [`${AdminAction.FetchGroupRoles}Handler`](state, { groupId, groupRoles }: {groupId: string, groupRoles: Models.Auth.Roles.RolesModel[] }) {
    const index = state.groups.findIndex((obj) => obj.id === groupId);
    if (index >= 0) {
      state.groups[index].roles = groupRoles;
    }
  },
  [`${AdminAction.SetCreateUserFlag}Handler`](state) {
    state.createUserFlag = !state.createUserFlag;
  },
};
